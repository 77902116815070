import React, { ChangeEvent, useEffect, useState } from 'react';
import IPageProps from '../../interfaces/page';
import {
    Action as action,
    IOpinionForModal,
    formatDateTime,
    getLoginInfo,
    getModalConfigs,
    replaceNewline,
    geIndexBytOpinionCode,
    OpinCode,
    IsubTopicForModal,
    sourceType,
    Export2Word,
    logFunctionName,
    formatDate,
    currentClass,
    ReacherArPictureName_before,
    downloadImage,
    alertEx,
    SummaryFileName,
    getFileNameString
} from './tool/helper';
import { getDrGroupByStuNoClass, getOpinionByStuNo } from '../../services/EP/EpApplyStudentService';
import StudentFunctionBar from './component/studentFunctionBar';
import { IApplyTopic, IOpinion, Param11OrPost11, IsubTopic, subTopicParam } from '../../interfaces/EpModal';
import TopNavPage1 from '../topNav1';
import { StudentDisplay } from './epStudentHome';
import { getSubTopicByGroupNo } from '../../services/EP/EpApplyExploreService';
import { Modal } from 'react-bootstrap';
import {
    createOpinion,
    createSubTopic,
    deleteOpinionByUID,
    deleteSubTopicByGroupNoSubNo,
    getOpinionByGroupMemOpIdx,
    getOpinionByGroupOpIdx,
    paramsCreateOpinionSubTopic,
    paramsUpdateSubTopic,
    updateOpinionByGroupOpIdxUID,
    updateSubTopicByUid
} from '../../services/EP/OpinionService';
import { BSModal } from '../../components/modal/bsmodal';
import { group } from 'console';
import { Uniform } from '../../assets/js/plugins/threejs';
import { FileUploader } from '../../components/fileUploader';
import { isDisabled } from '@testing-library/user-event/dist/utils';
import ExportButton from './component/exportButton';
import { checkFileExists, getuploadtime } from '../../services/DrFileService';
import FileUploaderNew from '../../components/fileUploaderNew';

const EpStudLevel1: React.FunctionComponent<IPageProps> = () => {
    const [mode, setMode] = useState<string>('');
    // const [mode,setMode] =useState<string>('')
    /** 子題 回饋前 */
    const [editMode, setEditMode] = useState();
    const [subTopicBeforeFeedbackList, setSubTopicBeforeFeedbackList] = useState<IsubTopic[]>();
    const [currentLoginInfo, setCurrentLoginInfo] = useState<any>();
    const [opinion, setOpinion] = useState<IOpinion>();
    const [studentInfo, setStudentInfo] = useState<StudentDisplay>();

    const [isAddRsearchModalOpen, setIsAddRsearchModalOpen] = useState<any>();
    const [currentShowModal, setCurrentShowModal] = useState<IOpinionForModal | IsubTopicForModal>();

    const [openErrModal, setOpenErrModal] = useState<boolean>(false);
    const [errMsg, setErrMsg] = useState();
    const [formData, setFormData] = useState<paramsCreateOpinionSubTopic>({ UID: undefined, GroupNo: '', MemberNo: '', DataA: '', DataB: '', DataC: '', ParamType: '' });
    const [isReload, setIsReload] = useState<boolean>(false);
    const [imgkey, setImgkey] = useState(0);
    const [isUploading, setIsUploading] = useState(false)
    const [isPictureExist, setIsPictureExist] = useState(false)
    const [fileUpdateTime, setFileUpdateTime] = useState("")


    useEffect(() => {
        getIsFileExist()

        getuploadtimes();
    }, [opinion])

    const handleSubmit = (event: any) => {
        event.preventDefault(); // 阻止表单提交
        // 你的提交逻辑
    };

    const getIsFileExist = async () => {
        const rsp = await checkFileExists(`/uploads/upload${currentClass}/${opinion?.GroupNo}`, ReacherArPictureName_before, "")

        if (rsp == '檔案已存在.') {
            setIsPictureExist(true)

        } else {

            setIsPictureExist(false)
        }
    }


    const getuploadtimes  = async () => {
        const rsp = await getuploadtime(`/uploads/upload${currentClass}/${opinion?.GroupNo}`, ReacherArPictureName_before)


        console.log({rsp})
            // setFileUpdateTime(rsp.time)

       
    }


    useEffect(() => {
        fetchdataStudentInfo();
        FetchDataOpinion();
        // fetchDataSubTopic();
        setImgkey(3)
    }, [isAddRsearchModalOpen, isReload, isUploading]);

    useEffect(() => {
        fetchDataSubTopic();
    }, [studentInfo, isReload]);

    const reload = async () => {
        await fetchdataStudentInfo();
        await FetchDataOpinion();
    };

    /** 取得登入資料 */
    const fetchdataStudentInfo = async () => {
        /** 取得登入資料 */
        debugger
        try {
            let currentLoginInfo = getLoginInfo();
            setCurrentLoginInfo(currentLoginInfo);
            const studentInfo = (await getDrGroupByStuNoClass(currentClass, currentLoginInfo.LoginID)) as StudentDisplay; //as StudentInfo
            // const studentInfo =studentInfoList[0] ;
            console.log('currentLoginInfo', currentLoginInfo);

            setStudentInfo(studentInfo);
            // }
        } catch (ex) {
            alertEx('取得學生資料發生錯誤! ');
            console.log('取得學生資料發生錯誤! ', ex);
        }
    };
    /** */
    const FetchDataOpinion = async () => {
        let currentLoginInfo = await getLoginInfo();
        setCurrentLoginInfo(currentLoginInfo);
        debugger
        console.log('rsp..currentLoginInfo.', currentLoginInfo);
        alertEx(currentLoginInfo.LoginID);
        const rsp = (await getOpinionByStuNo(currentLoginInfo.LoginID)) as IOpinion[];
        console.log('rsp...opinion', rsp);
        const opinion = rsp[0];
        if (opinion) {
            setOpinion(opinion);
        }
    };


    /** 打開視窗 */
    const openEditModal = async (opinCode: OpinCode, action: action, param11?: Param11OrPost11, subTopic?: IsubTopic, enable?: boolean) => {

        if (enable) {
            return
        }
        let modalConfig = getModalConfigs(opinCode, action, 0);
        let params = {
            UID: undefined,
            ParamType: modalConfig?.sourceType,
            GroupNo: studentInfo?.GroupNo,
            MemberNo: studentInfo?.StuNo,
            DataA: '',
            DataB: '',
            DataC: ''
        } as paramsCreateOpinionSubTopic;

        setFormData(params);
        /**  彈出視窗設定檔*/
        if (studentInfo) {
            if (action === '新增') {
                params = {
                    action: '新增',
                    UID: undefined,
                    ParamType: modalConfig?.sourceType,
                    GroupNo: studentInfo?.GroupNo,
                    MemberNo: studentInfo?.StuNo,
                    DataA: '',
                    DataB: '',
                    DataC: ''
                } as paramsCreateOpinionSubTopic;

                setFormData(params);
                //需要有設定檔 跟 修改對象 ID
            } else if (action === '修改') {
                // fectchData todo
                if (modalConfig) {
                    //如果沒有 傳進來的物件
                    if (param11 && !subTopic) {
                        // params
                        params = {
                            action: '修改',
                            UID: param11.OpinionID,
                            ParamType: modalConfig.sourceType,
                            GroupNo: studentInfo?.GroupNo,
                            MemberNo: studentInfo?.StuNo,
                            DataA: param11.DataA,
                            DataB: param11.DataB,
                            DataC: '',
                            termsStringList: param11.DataB.split('、')
                        } as paramsCreateOpinionSubTopic;
                        setFormData(params);
                    } else if (!param11 && subTopic) {
                        // 沒有物件
                        try {
                            params = {
                                action: '修改',
                                UID: subTopic.uid,
                                ParamType: modalConfig.sourceType,
                                GroupNo: studentInfo?.GroupNo,
                                MemberNo: studentInfo?.StuNo,
                                SubNo: subTopic.SubNo + '',
                                SubName: subTopic.SubName,
                                Description: subTopic.Description
                            } as paramsCreateOpinionSubTopic;

                            setFormData(params);
                        } catch (ex) {
                            alertEx('ex:' + JSON.stringify(ex));
                        }
                    }
                }
            } else if (action === '新增或修改') {
                if (modalConfig) {
                    // 有moaal 設定檔
                    if (!param11 && !subTopic) {
                        // topic
                        try {
                            let optionCode = geIndexBytOpinionCode(opinCode);
                            let rsp = (await getOpinionByGroupOpIdx(modalConfig.isfeedback, optionCode, studentInfo?.GroupNo)) as any[];
                            if (rsp.length > 0) {
                                // 原本有資料 設定修改
                                params = {
                                    action: '修改',
                                    UID: rsp[0].OpinionID || rsp[0].uid,
                                    ParamType: modalConfig.sourceType,
                                    GroupNo: studentInfo?.GroupNo,
                                    MemberNo: studentInfo?.StuNo,
                                    DataA: rsp[0].DataA || null,
                                    DataB: rsp[0].DataB || null,
                                    DataC: rsp[0].DataC || null
                                } as paramsCreateOpinionSubTopic;

                                setFormData(params);
                            } else {
                                // 沒有資料就設定  新增
                                params = {
                                    action: '新增',
                                    UID: undefined,
                                    ParamType: modalConfig?.sourceType,
                                    GroupNo: studentInfo?.GroupNo,
                                    MemberNo: studentInfo?.StuNo,
                                    DataA: '',
                                    DataB: '',
                                    DataC: ''
                                } as paramsCreateOpinionSubTopic;
                                setFormData(params);
                            }
                        } catch (ex) {
                            alertEx('ex:' + JSON.stringify(ex));
                        }
                    } else if (!param11 && subTopic) {
                    } else if (param11 && !subTopic) {
                        //opinion 一對一
                    }
                }
            } else if (action === '刪除') {
            }
        }

        // console.log( getModalConfigs(stage,modalName) )
        if (getModalConfigs(opinCode, action, 0)) {
            setCurrentShowModal(getModalConfigs(opinCode, action, 0));
        }
        setIsAddRsearchModalOpen(true);
    };

    const handleCheckboxClick = (index: number) => {

        const updatedTeam = [...(studentInfo?.Team || [])];
        updatedTeam.forEach(x => {
            if (formData?.Description?.includes(x.TStuName.trim())) {
                x.checked = true;
            }
        })
        updatedTeam[index].checked = !updatedTeam[index].checked;
        setStudentInfo({
            ...studentInfo,
            Team: updatedTeam,
        } as StudentDisplay);

        let studentString = updatedTeam.filter((i) => i.checked).map(x => x.TStuName.trim()).join('、')
        setFormData({ ...formData, Description: studentString })

    };


    const deleteItem = async (deleteType: sourceType, subTopic?: IsubTopic, param11?: Param11OrPost11, enable?: boolean) => {
        if (enable) {
            return
        }
        alertEx(deleteItem.name);
        if (window.confirm('確定刪除?')) {
            try {
                if (deleteType === 'subTopic' && subTopic) {
                    if (subTopic.GroupNo && subTopic.uid) {
                        await deleteSubTopicByGroupNoSubNo(0, subTopic.GroupNo, subTopic.SubNo);
                    }
                } else if (deleteType === 'opinion' && param11) {
                    await deleteOpinionByUID(0, param11.OpinionID);
                }
                alertEx('刪除成功!');
                setIsReload(!isReload);
            } catch (ex) {
                console.log();
            }
        }
    };
    /** */
    const fetchOpinionByGroupAndIndex = async () => { };

    /** 儲存更新 */
    const saveEditModal = async (target: IOpinionForModal | any) => {

        if (!target) {
            alert('發生錯誤!');
            return;
        }
        let isPass = validate(target, formData, '');
        if (!isPass) {
            return false;
        }
        //  往下繼續執行
        if (formData.action === '新增') {
            if (target.sourceType === 'opinion' && !(target.opinCode == '1_1提出探究題目')) {
                //一般寫入 opinion 的項目
                //param11
                let params = { GroupNo: studentInfo?.GroupNo, DataA: formData.DataA, DataB: formData.DataB, DataC: formData.DataC } as paramsCreateOpinionSubTopic;

                alertEx('儲存物件 opinion' + JSON.stringify(params));
                try {
                    await createOpinion(params, target.isfeedback, target.index);
                    alert('儲存成功!');
                    setIsAddRsearchModalOpen(false);
                } catch (ex) {
                    alert('err: ' + JSON.stringify(ex));
                }
            } else if (target.sourceType === 'opinion' && target.opinCode == '1_1提出探究題目') {
                //一般寫入 opinion 的項目
                //param11
                let params = { GroupNo: studentInfo?.GroupNo, MemberNo: studentInfo?.StuNo, DataA: formData.DataA, DataB: formData.DataB, DataC: formData.DataC } as paramsCreateOpinionSubTopic;

                console.log('儲存物件 opinion 1_1提出探究題目', JSON.stringify(params));
                try {
                    await createOpinion(params, target.isfeedback, target.index);
                    alert('儲存成功!');
                    setIsAddRsearchModalOpen(false);
                } catch (ex) {
                    alert('err: ' + JSON.stringify(ex));
                }
            } else if (target.sourceType === 'subTopic') {
                if (!studentInfo?.GroupNo) {
                    alert('發生錯誤!');
                    return;
                }
                let params = {
                    GroupNo: studentInfo?.GroupNo?.trim(),
                    SubName: formData.DataB?.trim(),
                    Description: formData.Description?.trim() || ''
                } as subTopicParam;

                alertEx('儲存物件' + JSON.stringify(params));
                try {
                    await createSubTopic(params, target.isfeedback);

                    alertEx('儲存成功!');
                    setIsAddRsearchModalOpen(false);
                } catch (ex) {
                    alertEx('err: ' + JSON.stringify(ex));
                    console.log('ex', JSON.stringify(ex));
                }
            }

            setIsAddRsearchModalOpen(false);
        } else if (formData.action === '修改' && formData.UID) {
            if (formData.ParamType == 'subTopic') {
                //修改子題
                // "uid":9182,"GroupNo":"FA001-01","SubNo":1,"SubName":"我是子題一AAAAA","Description":"趙小一，趙小二","Keyword":null,"DeleteDate":null
                let params = {
                    uid: formData.UID,
                    GroupNo: studentInfo?.GroupNo,
                    SubNo: formData.SubNo,
                    SubName: formData.SubName,
                    Description: formData.Description || '',
                    Keyword: null
                };
                try {
                    await updateSubTopicByUid(params, 0, formData.UID);
                } catch (ex) {
                    alertEx(JSON.stringify(ex));
                }
            } else {
                // 一般儲存

                try {
                    let params = {
                        GroupNo: studentInfo?.GroupNo,
                        MemberNo: studentInfo?.StuNo,
                        DataA: formData.DataA,
                        DataB: formData.DataB,
                        DataC: formData.DataC
                    } as paramsCreateOpinionSubTopic;

                    await updateOpinionByGroupOpIdxUID(params, 0, target.index, formData.GroupNo, formData.UID);

                    alertEx('儲存成功!');
                    setIsAddRsearchModalOpen(false);
                } catch (ex) {
                    alertEx(ex);
                }
            }
        } else if (formData.action === '刪除') {
            if (window.confirm('確定刪除?')) {
            }
        }
        setIsAddRsearchModalOpen(false);
    };

    /**
     *
     *  判斷是否錯誤 跟提供錯誤訊息 */
    const validate = (target: IOpinionForModal, formatDate: paramsCreateOpinionSubTopic, source: string): boolean => {

        let errMsg = '';
        let result = true;
        target.inputItems.forEach((item) => {
            let reg = item.regex;
            if (!reg) {
                return;
            }
            const isPass = reg.test(formatDate[item.dataABC].trim());
            if (!isPass) {
                result = false;

                errMsg += item.inputLable + ':' + item.validateWord;
                errMsg += '\n';
            }
        });

        if (errMsg) {
            alert(errMsg);
        }
        return result;
    };

    useEffect(() => {
        fetchEditTargInfo();
    }, [isAddRsearchModalOpen]);

    const fetchEditTargInfo = async () => {
        if (currentShowModal) {
            let target = getModalConfigs(currentShowModal?.opinCode, currentShowModal.action, 0) as IOpinionForModal;

            if (target && studentInfo && target.sourceType == 'opinion') {
                try {
                    // 取得編輯資料
                    let rsp = await getOpinionByGroupMemOpIdx(target.isfeedback, target.index, studentInfo.GroupNo, studentInfo.StuNo);

                    console.log('rsp', rsp);
                } catch (ex) {
                    alertEx(ex);
                }
            }
        }
    };


    /** 取得子題資料*/
    const fetchDataSubTopic = async () => {
        if (studentInfo?.GroupNo) {
            let subTopicbefore = (await getSubTopicByGroupNo(0, studentInfo?.GroupNo)) as IsubTopic[];

            setSubTopicBeforeFeedbackList(subTopicbefore);
        }
    };
    const scrollToTop = () => {
        window.scrollTo(0, 0); // 滚动到页面顶部
    };


    return (
        <>
            <TopNavPage1 />
            {/* 主要畫面 */}
            <div className="section pt-3">
                <div className="container px-3 px-md-4">
                    <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
                        <h5 className="title mb-3 me-3">專題探究競賽</h5>
                        <StudentFunctionBar />
                    </div>
                    <div className="text-center h5 mt-4">階段1和2</div>
                    <div>
                        {studentInfo?.SchoolName} {studentInfo?.GroupName}({studentInfo?.GroupNo})-{studentInfo?.StuName}({studentInfo?.StuNo})
                    </div>
                    <div id="title-info" hidden>
                        {studentInfo?.SchoolName.trim()} {studentInfo?.GroupName.trim()}({studentInfo?.GroupNo})

                    </div>
                    <div className="accordion" id="accordionRental">
                        <div className="accordion-item">
                            <div className="card bd-callout bd-callout-danger" >
                                <button className="accordion-button text-dark p-0" 
                                        type="button" 
                                        data-bs-toggle="collapse" 
                                        data-bs-target="#level1" 
                                        aria-expanded="false"
                                    aria-controls="level1"

                                >
                                    <h4 className="my-0 me-3">階段1、決定探究題目</h4>
                                    <div className="text-second font-weight-bold">
                                        (階段1和2，請於 <span>112/03/24</span> 前完成)
                                    </div>
                                    <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                    <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                </button>
                            </div>

                            <div className="collapse " id="level1" >
                                <div className="bd-callout d-flex flex-row flex-wrap justify-content-between align-items-center px-4 py-3 mt-0 mb-3">
                                    <div>
                                        <div>「訂題目」是個說難不難、說簡單也不簡單的事。</div>
                                        <div>希望每個隊員都能想想自己想探究的題目，啟動好奇心和求知欲。</div>
                                        <div>小隊再透過共同討論決定題目，並預測探究最後可能的結果。</div>
                                        <div>一起進入數位閱讀專題探究的世界吧！GO！</div>
                                    </div>
                                    <div>
                                        <div className="btn text-second bg-second mb-0 me-3 pointer" data-bs-toggle="modal" data-bs-target="#teach1">
                                            怎樣選擇題目
                                        </div>

                                        {/* <ExportButton stageId='stage1' stageName='階段一' /> */}
                                        <div className="btn text-second bg-second mb-0 pointer" onClick={() => Export2Word('stage1', '階段1', getFileNameString(undefined, studentInfo))}>
                                            匯出檔案
                                        </div>
                                    </div>
                                </div>
                                <div className="card p-4 pt-3 " id="stage1">
                                    <h5 className="fw-bolder pt-3">1-1 提出探究題目</h5>
                                    <div className="d-flex flex-wrap align-items-end">
                                        <ul>
                                            <li>每個學生最少提出一個題目，最好不超過3個。不能編輯其他成員的資料。</li>
                                            <li>
                                                此階段希望每個學生都能先提出<b>「自己」「想探究」</b>的題目，並說明理由。
                                            </li>
                                        </ul>
                                        <div
                                            className="btn text-second bg-second mb-0 pointer ms-auto"
                                            data-bs-toggle="modal"
                                            data-bs-target="#editModal1-1"
                                            onClick={() => openEditModal('1_1提出探究題目', '新增')}
                                        >
                                            <span className="material-icons-outlined align-middle">add</span>
                                            <span>提出探究題目</span>
                                        </div>
                                    </div>
                                    <div className="table-responsive pt-3">
                                        <table className="text-sm table table-striped">
                                            <thead>
                                                <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6;' }}>
                                                    <th className="p-2 text-nowrap">成員</th>
                                                    <th className="p-2 text-nowrap">我想探究的題目</th>
                                                    <th className="p-2 text-nowrap">想探究這個題目的理由</th>
                                                    <th className="p-2 text-nowrap">日期</th>
                                                    <th className="p-2 text-nowrap print-remove">功能</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {opinion?.Pre11.map((item) => (
                                                    <tr>
                                                        <td className="text-nowrap">{item.StuName}</td>
                                                        <td>{item.DataA}</td>
                                                        <td>{item.DataB}</td>
                                                        <td> {formatDateTime(item.UpdateDate)}</td>
                                                        <td className="text-nowrap print-remove">
                                                            {/* {JSON.stringify( item.StuNo ===studentInfo?.StuNo)} */}
                                                            <span
                                                                className={item.StuNo === studentInfo?.StuNo ? 'material-icons text-dblue cursor-pointer' : 'material-icons text-gray'}
                                                                title="刪除"
                                                                onClick={() => deleteItem('opinion', undefined, item, item.StuNo !== studentInfo?.StuNo)}
                                                            >
                                                                delete_outline
                                                            </span>
                                                            <span
                                                                className={item.StuNo === studentInfo?.StuNo ? 'material-icons text-dblue cursor-pointer' : 'material-icons text-gray'}
                                                                title="編輯"
                                                                onClick={() => openEditModal('1_1提出探究題目', '修改', item, undefined, item.StuNo !== studentInfo?.StuNo)}
                                                            >
                                                                edit
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">1-2 決定探究題目</h5>
                                    <ul>
                                        <li>請全體隊員共同討論後，決定一個題目。</li>
                                        <li>這裡全體隊員均可編輯。</li>
                                    </ul>
                                    <div className="concard p-3">
                                        <span
                                            className="material-icons float-end pointer"
                                            data-bs-toggle="modal"
                                            data-bs-target="#editModal1-2"
                                            title="編輯"
                                            onClick={() => openEditModal('1_2決定探究題目', '新增或修改')}
                                        >
                                            edit
                                        </span>
                                        <div className="fw-bolder text-second pb-2">
                                            我們決定探究的題目：
                                            <span className="text-primary">{opinion?.Pre12_DataA}</span>
                                        </div>
                                        <div className="fw-bolder text-second pt-3 pb-2">決定原因：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre12_DataB || '') }}>

                                        </div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>
                                                {/* 2023/02/20 下午 03:39:00  */}
                                                {formatDateTime(opinion?.Pre12_UpdateDate)}
                                            </span>
                                        </div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">最後編輯：</span>
                                            <span>
                                                {/* 2023/02/20 下午 03:39:00  */}
                                                {replaceNewline(opinion?.Pre12_StuName)}
                                            </span>
                                        </div>
                                    </div>
                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">1-3 預測可能的結果</h5>
                                    <ul>
                                        <li>請全體隊員共同討論後，預測可能的結果。</li>
                                        <li>這裡全體隊員均可編輯。</li>
                                    </ul>
                                    <div className="concard p-3">
                                        <span
                                            className="material-icons float-end pointer"
                                            data-bs-toggle="modal"
                                            data-bs-target="#editModal1-3"
                                            title="編輯"
                                            onClick={() => openEditModal('1_3探究題目預測結果', '新增或修改')}
                                        >
                                            edit
                                        </span>
                                        <div className="fw-bolder text-second pt-3 pb-2">我們預測可能的結果是：</div>
                                        <div>

                                            <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre13_DataB || '') }}></div>
                                        </div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{formatDateTime(opinion?.Pre13_UpdateDate)}</span>
                                        </div>
                                    </div>

                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">1-4 困難、解決與新學習</h5>
                                    <ul>
                                        <li>請全體隊員共同回顧在「階段1」遇到怎樣的困難?最後如何解決? 在這過程中學到了什麼新思維？</li>
                                        <li>這裡全體隊員均可編輯。</li>
                                    </ul>
                                    <div className="concard p-3">
                                        <span
                                            className="material-icons float-end pointer"
                                            data-bs-toggle="modal"
                                            data-bs-target="#editModal1-4"
                                            title="編輯"
                                            onClick={() => openEditModal('1_4困難、解決與新學習', '新增或修改')}
                                        >
                                            edit
                                        </span>

                                        <div className="fw-bolder text-second pt-3 pb-2">我們在「階段1」遇到的困難：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre14_DataA || '') }}></div>
                                        <div className="fw-bolder text-second pt-3 pb-2">我們最後如何解決：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre14_DataB || '') }}></div>
                                        <div className="fw-bolder text-second pt-3 pb-2">我們學到的新思維：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre14_DataC || '') }}></div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{formatDateTime(opinion?.Pre14_UpdateDate)}</span>
                                        </div>
                                    </div>
                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">階段1，指導老師留言</h5>
                                    <div className="fw-bolder text-second pt-3 pb-2">老師留言：</div>
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre19_DataA || '') }}>
                                        {/* 學校裡的新住民子女學生人數大約在70人上下，其中包含許多不同的國家，題目中有些想討論『新住民媽媽』，有些想討論『越南新住民媽媽』，為什麼最後題目會將對象定為『越南新住民媽媽』？ */}
                                    </div>
                                    <div className="pt-3 pb-2">
                                        <span className="fw-bolder text-second">日期：</span>
                                        <span>{formatDateTime(opinion?.Pre19_UpdateDate)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <div className="card bd-callout bd-callout-danger">
                                <button
                                    className="accordion-button text-dark collapsed p-0"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#level2"
                                    aria-expanded="false"
                                    aria-controls="level2"
                                >
                                    <h4 className="my-0 me-2">階段2、規劃探究架構</h4>
                                    <div className="text-second font-weight-bold">
                                        (階段1和2，請於 <span>112/03/24</span> 前完成)
                                    </div>
                                    <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                    <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="collapse" id="level2">
                                <div className="bd-callout d-flex flex-row flex-wrap justify-content-between align-items-center px-4 py-3 mt-0 mb-3">
                                    <div>
                                        <div>東西太大咬不下時，我們會把它切成小塊好入口。</div>
                                        <div>專題探究也一樣，要把題目分成幾個子題，找資料才方便。</div>
                                        <div>每個子題可能還包括一些重要的元素，試著把架構畫出來！</div>
                                        <div>有了架構圖，探究時就不會迷路啦！</div>
                                    </div>
                                    <div>
                                        <div className="btn text-second bg-second mb-0 me-3 pointer" data-bs-toggle="modal" data-bs-target="#teach2">
                                            怎樣規劃探究架構
                                        </div>
                                        <div className="btn text-second bg-second mb-0 pointer" onClick={() => Export2Word('stage2', '階段2', getFileNameString(undefined, studentInfo))}>
                                            匯出檔案
                                        </div>
                                    </div>
                                </div>
                                <div className="card p-4 pt-3" id="stage2">
                                    <h5 className="fw-bolder pt-3">2-1 擬定探究子題</h5>
                                    <ul>
                                        <li>把你們探究的題目再細分成若干個子題。</li>
                                        <li>建議細分成3~4個子題。</li>
                                        <li>這裡全體隊員均可編輯。</li>
                                    </ul>

                                    <div className="d-flex flex-wrap align-items-end">
                                        <div className="fw-bolder text-second pt-3 pb-2">
                                            本隊探究題目：
                                            <span className="text-primary">{opinion?.Pre12_DataA}</span>
                                        </div>
                                        <div
                                            className="btn text-second bg-second mb-0 pointer ms-auto"
                                            data-bs-toggle="modal"
                                            data-bs-target="#editModal2-1"
                                            onClick={() => openEditModal('新增探究子題', '新增')}
                                        >
                                            <span className="material-icons-outlined align-middle">add</span>
                                            <span>新增探究子題</span>
                                        </div>
                                    </div>

                                    <div className="table-responsive pt-3">
                                        <table className="text-sm table table-striped">
                                            <thead>
                                                <tr className="font-weight-bolder text-second" style={{ backgroundColor: '#d9dce6' }}>
                                                    <th className="p-2">#</th>
                                                    <th className="p-2 text-nowrap">子題名稱</th>
                                                    <th className="p-2 text-nowrap">負責隊員</th>
                                                    <th className="p-2 text-nowrap">日期</th>
                                                    <th className="p-2 text-nowrap print-remove">功能</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* {JSON.stringify(subTopicBeforeFeedbackList)} */}
                                                {subTopicBeforeFeedbackList?.map((item) => (
                                                    <tr>
                                                        <td>{item.SubNo}</td>
                                                        <td>{item.SubName}</td>
                                                        <td className="text-nowrap">{item.Description || ''}</td>
                                                        <td>{formatDate(item.UpdateDate)}</td>
                                                        <td className="text-nowrap print-remove">
                                                            <span className="material-icons text-dark pointer me-3" onClick={() => deleteItem('subTopic', item)} title="刪除">
                                                                delete_outline
                                                            </span>
                                                            <span
                                                                className="material-icons text-dark pointer"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#editModal2-1"
                                                                title="編輯"
                                                                onClick={() => openEditModal('修改探究子題', '修改', undefined, item)}
                                                            >
                                                                edit
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">2-2 繪製探究架構</h5>
                                    <ul>
                                        <li>使用你熟悉的軟體繪製，如 Powerpoint、Xmind、Cacoo等。手繪後拍照上傳亦可，要清晰並轉正。</li>
                                        <li>存成副檔名為jpg的檔案後上傳。</li>
                                        <li>重傳檔案後若沒有立即更新圖片，請重整網頁(Ctrl+F5)，或清除瀏覽器歷史記錄的快取圖片。</li>
                                        <li>這裡全體隊員均可編輯。</li>
                                    </ul>
                                    {/* 
                                      <div className="rounded-3 mx-auto p-3 imgborder text-center">
                            <div className="d-flex justify-content-center align-items-start"> */}
      {/* <div className="d-flex justify-content-center align-items-start">

<img src={"./assets/img/fun/chart/" + funText.Groupno + ".jpg"} alt="" className="align-text-top w-100 imgs"
    style={{ maxWidth: '90%' }} />

</div> */}
                            
                                    <div className="rounded-3 mx-auto p-3 imgborder text-center">
                                        <div className="d-flex justify-content-center align-items-start">
                                            {isPictureExist ? (
                                                <div>
                                                <img
                                                    className="print-remove align-text-top w-100 imgs"
                                                    key={imgkey}
                                                    src={`/uploads/upload${currentClass}/${opinion?.GroupNo}/${ReacherArPictureName_before}?v=${new Date().getSeconds()}`}
                                                    alt=""
                                                    // width="500"
                                                    style={{ maxWidth: '90%' , maxHeight:"" }}
                                                    
                                                /> 
                                            
                                                        <div className='mt-3'>
                                                        <button 
                                                        onClick={() => downloadImage(`/uploads/upload${currentClass}/${opinion?.GroupNo}/${ReacherArPictureName_before}`, '研究架構.png')}
                                                        type="button" 
                                                        className="btn btn-secondary" 
                                                        data-bs-dismiss="modal" 
                                                     >
                                                     下載架構圖 
                                                     </button>

                                                        </div>
                                             
                                                </div>
                                            ) : (
                                                <img className="print-remove " src="/assets/img/ep/file_upload.png" alt="" />
                                            )}
                                        </div>
                                    </div>
                                    <div className="mx-auto my-3">


                                        <FileUploaderNew
                                            fileType="image/jpeg,image/jpg"
                                            uploaderDir={`/upload${currentClass}/${opinion?.GroupNo}`}
                                            buttonStr="上傳檔案"
                                            setIsReload={setIsReload} isReload={isReload}
                                            setIsUploading={setIsUploading} isUploading={isUploading}
                                            NewFileName={ReacherArPictureName_before}
                                        />

                                        {/* <FileUploader 
                                        fileType="image/jpeg,image/jpg"
                                        uploaderDir={`/upload${currentClass}/${opinion?.GroupNo}`} 
                                        buttonStr="上傳檔案" 
                                        setIsReload={ setIsReload } isReload ={isReload}  
                                        setIsUploading={setIsUploading } isUploading ={isUploading}
                                        NewFileName ={ReacherArPictureName_before} 
                                        /> */}
                                    </div>

                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">2-3 困難、解決與新學習</h5>
                                    <ul>
                                        <li>請全體隊員共同回顧在「階段2」遇到怎樣的困難?最後如何解決? 在這過程中學到了什麼新思維？</li>
                                        <li>這裡全體隊員均可編輯。</li>
                                    </ul>
                                    <div className="concard p-3">
                                        <span
                                            className="material-icons float-end pointer"
                                            data-bs-toggle="modal"
                                            data-bs-target="#editModal2-3"
                                            title="編輯"
                                            onClick={() => openEditModal('2_4困難、解決與新學習', '新增或修改')}
                                        >
                                            edit
                                        </span>
                                        <div className="fw-bolder text-second pt-3 pb-2">我們在「階段2」遇到的困難：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre24_DataA || '') }}></div>
                                        <div className="fw-bolder text-second pt-3 pb-2">我們最後如何解決：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre24_DataB || '') }}></div>
                                        <div className="fw-bolder text-second pt-3 pb-2">我們學到的新思維：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre24_DataC || '') }}></div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{formatDateTime(opinion?.Pre24_UpdateDate)}</span>
                                        </div>
                                    </div>
                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">階段2，指導老師留言</h5>
                                    <div className="fw-bolder text-second pt-3 pb-2">老師留言：</div>
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre29_DataA || '') }}>{/* 學校裡的新住民子女學生人數大約在70人上下 */}</div>
                                    <div className="pt-3 pb-2">
                                        <span className="fw-bolder text-second">日期：</span>
                                        <span>{formatDateTime(opinion?.Pre29_UpdateDate)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pt-3 mt-4"></div>
                </div>
            </div>

            {/* <!-- 1-1 提出探究題目 --> ////////////////////////////////////////////////////////////////////////////////////////////////////////*/}

            <Modal show={isAddRsearchModalOpen} onHide={() => setIsAddRsearchModalOpen(false)}>
                <Modal.Header style={{ maxWidth: '800px' }}>
                    {/* <Modal.Title><h5 className="modal-title">{editMode}新增/修改探究題目</h5></Modal.Title> */}
                    <Modal.Title>
                        <h5 className="modal-title">{currentShowModal?.modalTitleDisplay}</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className=" px-3 pb-4">
                    <div>
                        <form onSubmit={handleSubmit} role="form text-left">
                            {currentShowModal?.inputItems.map((item) => (
                                <div className="row">
                                    <div className="col-12  pb-4">
                                        <label className="">{item.inputLable} </label>
                                        <div className="">
                                            {item.type === 'input' ? (
                                                <>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={item.placeholder}
                                                        // value="越南的推薦美食和台灣的有什麼不同？"
                                                        value={formData ? formData[item.dataABC] : undefined}
                                                        onChange={(e) => setFormData({ ...formData, [item.dataABC]: e.target.value })}
                                                        disabled={item.disable}
                                                        hidden={item.checkSection}
                                                        readOnly={item.readOnly}

                                                    />
                                                    {item.checkSection ?

                                                        (<div style={{ padding: "5px" }} >{studentInfo?.Team.map((item, index) => (
                                                            <>
                                                                <input style={{ marginLeft: "10px", padding: "10px" }} name='student' type="checkbox" checked={(item.checked || formData.Description?.includes(item.TStuName.trim()))}
                                                                    onClick={() => handleCheckboxClick(index)} />
                                                                <label htmlFor="student" onClick={() => handleCheckboxClick(index)} >{item.TStuName}</label>
                                                            </>

                                                        ))}</div>) : <></>}
                                                </>
                                            ) : (
                                                <textarea
                                                    rows={3}
                                                    className="form-control"
                                                    placeholder={item.placeholder}
                                                    //   value="因為每個國家的飲食文化和習慣都不同，所以我想知道台灣的飲食文化跟越南的飲食文化有什麼差異？              "
                                                    value={formData ? formData[item.dataABC] : undefined}
                                                    onChange={(e) => setFormData({ ...formData, [item.dataABC]: e.target.value })}
                                                ></textarea>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setIsAddRsearchModalOpen(false)}>
                        取消
                    </button>
                    <button type="button" className="btn btn-primary" onClick={() => saveEditModal(currentShowModal)}>
                        儲存
                    </button>
                    {/* <button type="button" className="btn btn-primary m-0" >關閉</button> */}
                </Modal.Footer>
            </Modal>


            {/* <!-- 階段1 教學 --> */}
            <div className="modal fade" id="teach1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '720px' }}>
                    <div className="modal-content mx-3">
                        <div className="modal-header">
                            <h5 className="modal-title">階段1 教學</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" className="text-dark" style={{ fontSize: '36px', lineHeight: '12px' }}>
                                    &times;
                                </span>
                            </button>
                        </div>
                        <div className="modal-body text-center pb-3 px-0" style={{ overflowY: 'auto' }}>
                            <iframe
                                src="//www.slideshare.net/slideshow/embed_code/key/HL4VzukgbKTTV9"
                                className="w-100 border-radius-lg"
                                style={{ border: '1px solid #CCC', height: '400px', maxWidth: '620px' }}
                                allowFullScreen
                            ></iframe>
                            <div>
                                <a href="//www.slideshare.net/LoveBook2/1202301pptx" title="數位閱讀專題探究競賽 - 怎樣選擇題目" target="_blank">
                                    數位閱讀專題探究競賽 - 怎樣選擇題目
                                </a>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                關閉
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- 階段2 教學 --> */}
            <div className="modal fade" id="teach2" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '720px' }}>
                    <div className="modal-content mx-3">
                        <div className="modal-header">
                            <h5 className="modal-title">階段2 教學</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" className="text-dark" style={{ fontSize: '36px', lineHeight: '12px' }}>
                                    &times;
                                </span>
                            </button>
                        </div>
                        <div className="modal-body text-center pb-3 px-0" style={{ overflowY: 'auto' }}>
                            <iframe
                                src="//www.slideshare.net/slideshow/embed_code/key/toNtzJl5dVvQVd"
                                className="w-100 border-radius-lg"
                                style={{ border: '1px solid #CCC', height: '400px', maxWidth: '620px' }}
                                allowFullScreen
                            ></iframe>
                            <div>
                                <a href="//www.slideshare.net/LoveBook2/2202301pptx" title="數位閱讀專題探究競賽 - 怎樣規劃探究架構" target="_blank">
                                    數位閱讀專題探究競賽 - 怎樣規劃探究架構
                                </a>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                關閉
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <BSModal title={'基本資料欄位輸入錯誤'} isOpen={openErrModal} setOpenModal={setOpenErrModal}>
                {errMsg}
            </BSModal>
        </>
    );
};

export default EpStudLevel1;
