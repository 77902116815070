import React, { useState, useRef } from 'react';
import { uploadFile } from '../services/DrFileService';
import { BSModal } from './modal/bsmodal';

interface fileUploaderProps {
    uploaderDir:string;
    buttonStr:string;
    setIsReload : React.Dispatch<React.SetStateAction<boolean>>;
    isReload : boolean
    isUploading? :boolean
    setIsUploading : React.Dispatch<React.SetStateAction<boolean>>;
    fileType : "image/jpeg,image/jpg" | "application/pdf" |"application/vnd.openxmlformats-officedocument.presentationml.presentation" |
     "application/pdf,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-powerpoint"
     | ".ppt,.pptx" 
    NewFileName :string 
   
}
const FileUploaderNew: React.FC<fileUploaderProps> = ({
    uploaderDir,
    buttonStr,
    setIsReload,
    isUploading,
    setIsUploading,
    NewFileName  ,
    fileType
}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const fileInputRef = useRef<HTMLInputElement>(null);

    const triggerFileInput = () => {
        fileInputRef.current?.click();
    };

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            setIsUploading(true); // Indicate uploading start
            try {
                debugger
              
                const response = await uploadFile(uploaderDir, files[0] ,NewFileName);
                if (response === 200) {
                    setModalContent("檔案上傳成功");
                    setIsReload((prev) => !prev); // Reload or refresh state
                } else {
                    setModalContent("檔案上傳失敗");
                }
            } catch (error) {
                setModalContent("上傳過程中發生錯誤");
                console.error("Error uploading file:", error);
            } finally {
          
                    setIsUploading(false); // Reset uploading state
           
             
                setModalOpen(true); // Show modal with result
            }
        }
    };

    return (
        <div>
            <div className="btn text-second bg-second mx-auto pb-3" onClick={triggerFileInput}>
                {buttonStr}
            </div>
            <input
                accept={fileType}
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileUpload}
                disabled={isUploading} // Disable input while uploading
            />
            {isUploading && <p>檔案上傳中...</p>}
            <BSModal title="上傳檔案" isOpen={modalOpen} setOpenModal={setModalOpen}>
                {modalContent}
            </BSModal>
        </div>
    );
};

export default FileUploaderNew;
