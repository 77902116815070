import React, { useContext, useEffect, useState } from 'react';
import IPageProps from '../../interfaces/page';
import TopNavPage1 from '../topNav1';
import FooterPage from '../footer';
import { handleLink } from '../../modules/utils';
import ApplyFunctionBar from './component/applyFunctionBar';
import { alertEx, currentClass, formatDate, formatDateTime, getLoginInfo, validateSubmit } from './tool/helper';
import {
    createDrGroupByYrApplyNo,
    createDrStudentByGroup,
    createDrTeaGroupByTeaNoGroup,
    delDrStudentByUid,
    deleteDrGroupByYrGroupNo,
    deleteDrTeaGroupByGroupNo,
    getDrGroupPrimeByYrApplyNo,
    getDrStudentByGroup,
    getDrTeaGroupByGroupNo,
    updateDrGroupPrimeByYrGroupNo,
    updateDrStudentByNo
} from '../../services/EP/EPApplyGroupService';
import { IGroupTeacherInfo, IApplyFormData, IGroupInfo, IGroupStudent, IInsertApplyGroup, IUpdateApplyGroup, IUpdateApplyStudent, IInsertApplyStudent } from '../../interfaces/EpModal';
import { BSModal } from '../../components/modal/bsmodal';
import { Modal } from 'react-bootstrap';
import { getEPApplyByNo } from '../../services/EP/EpLoginService';
import DropdownSelect from './component/DropdownSelectProps';
import { MyContext } from '../home';
import Schedule from './component/schedule';

/**epApplyHome.html  【6-4-5	小隊基本資料】*/
const EpApplyGroup: React.FunctionComponent<IPageProps> = (props) => {
    const context = useContext(MyContext);
    // const {applyInfoShare }=useContext(MyContext);
    const [openErrModal, setOpenErrModal] = useState(false);
    const [groupDataList, setGroupDataList] = useState<IGroupInfo[]>([]);
    const [applyInfo, setApplyInfo] = useState<IApplyFormData>();
    /** 目前選得隊伍 */
    const [selectedGroup, setSelectedGroup] = useState<IGroupInfo>();
    /** 目前選擇的隊伍明細 */
    const [currentGroupStudentList, setCurrentGroupStudentList] = useState<IGroupStudent[]>([]);
    const [groupteacherList, setGroupTeacherList] = useState<IGroupTeacherInfo[]>([]);
    const [selectTeacher1, setSelectTeacher1] = useState<any>("");
    const [selectTeacher2, setSelectTeacher2] = useState<any>("");
    const [isReload, setIsReload] = useState<boolean>();
    const [errMsg, setErrMsg] = useState('');
    const [btndisable, setBtndisable] = useState(false);

    const [formDataGroup, setFormDataGroup] = useState<IGroupInfo>({
        ApplyNo: '',
        Class: '',
        CompLearn: '',
        FlagOk: '',
        FlagRevOK: '',
        GroupNo: '',
        Groupname: '',
        PptDate: '',
        Remark: '',
        SchoolFull: '',
        Schoolname: '',
        Type: '',
        UploadDate: '',
        uid: '',
        teacher1: '',
        teacher2: ''
    });

    const [formDataStudent, setFormDataStudent] = useState<IGroupStudent>({
        mode: '',
        Compare: '',
        GroupNo: '',
        Password: '',
        Remark: '',
        StuGrade: '',
        StuName: '',
        StuNo: '',
        uid: ''
    });


    const [isOpenEdit, setIsOpenEditGroup] = useState<boolean>(false);
    const [isOpenEditStudent, setIsOpenEditStudent] = useState<boolean>(false);
    const [mode, setMode] = useState<'新增' | '編輯' | ''>('');
    const [currentLoginInfo, setCurrentLoginInfo] = useState<any>();
    useEffect(() => {
        console.log("1.context", context?.applyInfoShare)
        const fetchData = async () => {
            let groupList = await fetchDataGroup();
            setSelectedGroup(groupList[0]);
        };
        getEPApplyByLoginID(sessionStorage.getItem('LoginID') + '');
        fetchData();
        if (selectedGroup) {
            fetchDataGroupStudent(selectedGroup.GroupNo);
        }
    }, [isReload]);

    useEffect(() => {

        if (selectedGroup) {
            fetchDataGroupStudent(selectedGroup.GroupNo);
        }
    }, [selectedGroup]);

    /**取得學生資料 */
    const fetchDataGroupStudent = async (groupNo: string) => {
        let groupStudentList = (await getDrStudentByGroup(groupNo)) as IGroupStudent[];
        setCurrentGroupStudentList(groupStudentList);
        // 取小隊指導老師
        let groupTeachers = (await getDrTeaGroupByGroupNo(groupNo)) as IGroupTeacherInfo[];
        setGroupTeacherList(groupTeachers);
        console.log('groupTeachers,', groupTeachers);
        console.log('groupStudentList...', groupStudentList);
    };

    /**  取資料 */
    const fetchDataGroup = async (): Promise<IGroupInfo[]> => {
        let currentLoginInfo = getLoginInfo();
        setCurrentLoginInfo(currentLoginInfo);
        let GroupDataList = (await getDrGroupPrimeByYrApplyNo(currentClass, currentLoginInfo.LoginID)) as IGroupInfo[];
        setGroupDataList(GroupDataList);
        console.log(' feachData groupDataList', groupDataList);
        return GroupDataList;
    };

    /** 取得登入者基本資訊 (申請者)*/
    const getEPApplyByLoginID = async (loginID: string) => {
        let rsp = await getEPApplyByNo(loginID);
        console.log('rsp', rsp[0]);
        setApplyInfo(rsp[0] as IApplyFormData);
    };

    const validate = (target: '學生' | '小隊') => {
        let noError = true;
        let errMsg = '';
        if (target === '小隊') {
            let errorMsgs = validateSubmit();
            // alert(selectTeacher1)
            if (!selectTeacher1) {

                errorMsgs.push("指導老師至少指派一位")
            }
            if (errorMsgs.length) {
                errMsg = errorMsgs.join('\n'); // 使用换行符 '\n' 连接数组的元素
            }
            return errMsg;
        }

        if (target === '學生') {
            let errorMsgs = validateSubmit();

            if (errorMsgs.length) {
                errMsg = errorMsgs.join('\n'); // 使用换行符 '\n' 连接数组的元素
            }

            return errMsg;
        }
    };

    /** 儲存小隊資料 */
    const insertOrUpdateGroupInfo = async (action: '新增' | '編輯' | '') => {
        setBtndisable(true);
        try {
            if (action === '新增') {
                const errMsg = validate('小隊');
                if (errMsg) {
                    setErrMsg(errMsg);
                    setOpenErrModal(true);
                    setBtndisable(false);
                    return;
                }
                if (applyInfo?.ApplyNo) {
                    let params: IInsertApplyGroup = {
                        groupName: formDataGroup.Groupname,
                        schoolName: applyInfo?.SchoolName,
                        schoolFull: applyInfo?.SchoolFullName,
                        type: '',
                        remark: formDataGroup.Remark,
                        compLearn: formDataGroup.CompLearn
                    } as IInsertApplyGroup;


                    let rsp = (await createDrGroupByYrApplyNo(params, currentClass, applyInfo?.ApplyNo)) as any;
                    if (rsp?.GroupNo) {
                        //開始新增老師
                        if (selectTeacher1) {
                            const param = { groupno: rsp.GroupNo, teano: selectTeacher1.TeaNo };
                            await createDrTeaGroupByTeaNoGroup(param);
                        }
                        if (selectTeacher2) {
                            const param = { groupno: rsp.GroupNo, teano: selectTeacher2.TeaNo };
                            await createDrTeaGroupByTeaNoGroup(param);
                        }
                    }

                    alert('小隊新增成功!');
                    setIsOpenEditGroup(false);
                    setIsReload(!isReload);
                    setBtndisable(false);
                    setSelectTeacher1('')
                    setSelectTeacher2('')
                }
            } else if (action === '編輯') {
                let params: IUpdateApplyGroup = {
                    groupName: formDataGroup.Groupname?.trim() || '',
                    schoolName: applyInfo?.SchoolName?.trim() || '',
                    schoolFull: applyInfo?.SchoolFullName?.trim() || '',
                    flagOK: formDataGroup.FlagOk ? 1 : 0, //報名審核 傳入1或0
                    flagRevOK: formDataGroup.FlagRevOK ? 1 : 0, //五階段完成 傳入1或0
                    uploadDate: '', //上傳成果報告日期
                    pptDate: formDataGroup.PptDate?.trim() || '', //上傳PPT日期
                    compLearn: formDataGroup.CompLearn?.trim() || '', //參加競賽，學習不參加競賽，取消報名
                    type: '',
                    remark: formDataGroup?.Remark?.trim() || ''
                } as IUpdateApplyGroup;

                if (applyInfo?.ApplyNo) {
                    try {
                        await updateDrGroupPrimeByYrGroupNo(params, currentClass, formDataGroup.GroupNo);
                        // 更新老師
                        debugger
                        await deleteDrTeaGroupByGroupNo(formDataGroup.GroupNo);
                        if (formDataGroup.GroupNo) {
                            //開始新增老師
                            debugger
                            if (selectTeacher1) {
                                alert(selectTeacher1.TeaNo)
                                const param = { groupno: formDataGroup.GroupNo, teano: selectTeacher1.TeaNo };
                                await createDrTeaGroupByTeaNoGroup(param);
                            }
                            if (selectTeacher2) {
                                const param = { groupno: formDataGroup.GroupNo, teano: selectTeacher2.TeaNo };
                                await createDrTeaGroupByTeaNoGroup(param);
                            }
                        }
                    } catch (ex) {
                        console.log('ex', ex);
                    }
                    closeModal();
                    setIsReload(!isReload);
                }
            }
        } catch (ex) {
            alert('儲存小隊發生錯誤 :' + JSON.stringify(ex));

        } finally {
            setBtndisable(false);
        }
    };

    const closeModal = () => {
        setIsOpenEditGroup(false);
    };

    /**儲存學生資料*/
    const insertOrUpdateStudentInfo = async (action: '新增' | '編輯' | '') => {
        setBtndisable(true);
        if (action === '編輯') {
            const errMsg = validate('學生');
            if (errMsg) {
                setErrMsg(errMsg);
                setOpenErrModal(true);
                return;
            }

            if (!errMsg) {
                try {
                    let param: IUpdateApplyStudent = {
                        groupno: formDataStudent?.GroupNo.trim(),
                        stuname: formDataStudent.StuName.trim(),
                        stugrade: formDataStudent.StuGrade.trim(),
                        password: formDataStudent.Password.trim(),
                        compare: formDataStudent.Compare?.trim(),
                        remark: formDataStudent.Remark?.trim() || ''
                    } as IUpdateApplyStudent;

                    await updateDrStudentByNo(param, formDataStudent.StuNo);

                    setIsOpenEditStudent(false);

                    setSelectedGroup({ ...selectedGroup } as IGroupInfo);
                    setBtndisable(false);
                } catch (ex) {
                    alert('編輯學生發生錯誤' + JSON.stringify(ex));
                }
            }
        } else if (action === '新增') {
            const errMsg = validate('學生');

            if (errMsg) {
                setErrMsg(errMsg);
                setOpenErrModal(true);
                return;
            }

            if (!errMsg) {
                try {
                    let param = {
                        StuName: formDataStudent.StuName,
                        StuGrade: formDataStudent.StuGrade.trim(),
                        Password: formDataStudent.Password.trim(),
                        Compare: formDataStudent.Compare?.trim(),
                        Remark: formDataStudent.Remark?.trim()
                    } as IInsertApplyStudent;
                    await createDrStudentByGroup(param, formDataStudent.GroupNo);

                    setIsOpenEditStudent(false);
                    setSelectedGroup({ ...selectedGroup } as IGroupInfo);
                    // setIsReload(!isReload);
                } catch (ex) {
                    alert('新增學生發生錯誤' + JSON.stringify(ex));
                }
            }
        }
    };

    /** 當設定畫面有改變時*/
    const setFormDataOnChange = (e: React.ChangeEvent<HTMLInputElement> | any, attr: string) => {
        setFormDataGroup({ ...formDataGroup, [attr]: e.target.value } as IGroupInfo);
    };

    const setFormDataStudentOnChange = (e: React.ChangeEvent<HTMLInputElement> | any, attr: string) => {
        setFormDataStudent({ ...formDataStudent, [attr]: e.target.value } as IGroupStudent);
    };

    /**  */
    useEffect(() => {
        groupDataList.forEach((group: IGroupInfo) => { });
    }, [groupDataList]);
    /** 刪除小隊資料 */
    const deleteGroup = async (targetGroup: IGroupInfo) => {
        const isdeleted = window.confirm('確定刪除小隊？');
        if (isdeleted) {
            try {
                await deleteDrGroupByYrGroupNo(currentClass, targetGroup.GroupNo);
                alert('刪除成功!');
                setIsReload(!isReload);
            } catch (ex) {
                alert('刪除小隊發生錯誤!');
            }
        }
    };
    const deleteStudent = async (targetStudent: IGroupStudent) => {
        const isdeleted = window.confirm('確定刪除學生？');
        if (isdeleted && targetStudent.uid) {
            try {
                await delDrStudentByUid(targetStudent.uid);
                alert('刪除成功!');
                setIsReload(!isReload);
            } catch (ex) {
                alert('刪除學生發生錯誤!' + JSON.stringify(ex));
            }
        }
    };

    const openGroupEditModal = (mode: '新增' | '編輯' | '', group?: IGroupInfo) => {
        if (group && mode === '編輯') {
            setFormDataGroup(group);
        } else if (mode === '新增') {
            setFormDataGroup({
                ApplyNo: '',
                Class: '',
                CompLearn: '',
                FlagOk: '',
                FlagRevOK: '',
                GroupNo: '',
                Groupname: '',
                PptDate: '',
                Remark: '',
                SchoolFull: '',
                Schoolname: '',
                Type: '',
                UploadDate: '',
                uid: ''
            } as IGroupInfo);
        }
        setMode(mode);
        setIsOpenEditGroup(true);
        setSelectTeacher1('')
        setBtndisable(false)
    };

    const openStudentEditModal = (mode: '新增' | '編輯' | '', student?: IGroupStudent) => {
        // 確認目前小隊人數
        if (currentGroupStudentList.length === 10) {
            alert('小隊人數最多10人!');
            return;
        }

        if (student && mode === '編輯') {
            setFormDataStudent(student);
        } else if (mode == '新增') {
            setFormDataStudent({
                mode: mode,
                Compare: '',
                GroupNo: selectedGroup?.GroupNo?.trim(),
                Password: '',
                Remark: '',
                StuGrade: '',
                StuName: '',
                StuNo: '',
                uid: ''
            } as IGroupStudent);
        }
        setMode(mode);
        setIsOpenEditStudent(true);
        setBtndisable(false)
    };

    /** 打開 */

    return (
        <>
            <TopNavPage1 />
            <div className="section pt-3">
                <div className="container px-3 px-md-4">
                    <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
                        <h5 className="title mb-3 me-3">專題探究競賽</h5>
                        <ApplyFunctionBar crtlInfoList={context?.crtlInfoList} />
                        {/* {JSON.stringify(context?.crtlInfoList)}  */}
                    </div>
                    <Schedule />
                    <div className="text-center h5 mt-4">小隊基本資料</div>
                    <div className="text-muted fs-18 fw-bold mb-3">小隊基本資料增修說明</div>
                    <ol>

                    {/* "小隊基本資料增修說明文字調整
                    1.「小隊名稱」可使用中英文或數字...(此欄位皆相同)
                    2.請將以上師生編號及密碼交給競賽小隊成員，並填寫「參賽聲明書」後，即完成報名。
                    3.若新增小隊編號後卻未報名...(此欄位皆相同)
                    4.第1階段開始後「小隊名稱」不能更改。
                    5.第1~2階段期間，可申請更換隊員或指導老師(皆以一次為限)，需主動連繫閱讀基金會。" */}


                        <li>
                            「小隊名稱」可使用中英文或數字(不可使用其他文字或&lt;&gt;：“/\|？*等符號，若要凸顯字詞建議使用「」單引號)。
                            <br />
                            純中文隊名以8個字內為原則，英文或中英文數字混用隊名(含空白)不可超過15個字元。
                        </li>
                        <li>請將以上師生編號及密碼交給競賽小隊成員，並填寫「參賽聲明書」後，即完成報名。</li>
                        <li>若新增小隊編號後卻未報名，請在「小隊名稱」欄位上註明("刪除")即可。</li>
                        <li>第1階段開始後「小隊名稱」不能更改。</li>
                        <li>第1~2階段期間，可申請更換隊員或指導老師(皆以一次為限)，需主動連繫閱讀基金會。</li> 
                       
                    </ol>
                    <hr className="horizontal dark" />
                    <div className="d-flex flex-wrap mb-3 mt-4">
                        <div className="text-muted fs-18 fw-bold">
                            {applyInfo?.SchoolName}  承辦人: {applyInfo?.ApplyName} ({applyInfo?.ApplyNo})
                        </div>
                        <div className="btn text-second bg-second pointer mb-0 ms-auto" data-bs-toggle="modal" data-bs-target="#editModal"
                            onClick={() => openGroupEditModal('新增')}>
                            <span className="material-icons-outlined align-middle">add</span>
                            <span>新增小隊</span>
                        </div>
                    </div>
                    <div className="card p-4 pt-3">
                        <div className="table-responsive pt-3">
                            <table className="text-sm table table-striped mb-0">
                                <thead>
                                    <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                                        <th className="p-2 text-nowrap">小隊編號</th>
                                        <th className="p-2 text-nowrap">小隊名稱</th>
                                        <th className="p-2 text-nowrap">競賽/學習</th>
                                        <th className="p-2 text-nowrap">報名審核OK</th>
                                        <th className="p-2 text-nowrap">完成成果報告</th>
                                        <th className="p-2 text-nowrap">備註</th>
                                        <th className="p-2 text-nowrap" style={{ width: '100px' }}>
                                            功能
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {groupDataList.map((group) => (
                                        <tr>
                                            <td>{group.GroupNo}</td>
                                            <td>{group.Groupname}</td>
                                            <td>{group.CompLearn}</td>
                                            <td>{group.FlagOk}</td>
                                            <td>
                                                {formatDate(group.UploadDate)}
                                                {/* {group.UploadDate} */}
                                            </td>

                                            <td>{group.Remark}</td>
                                            <td className="text-nowrap">
                                                <span className="material-icons text-dark pointer me-3" onClick={() => deleteGroup(group)} title="刪除">
                                                    delete_outline
                                                </span>
                                                <span
                                                    onClick={() => {
                                                        openGroupEditModal('編輯', group);
                                                    }}
                                                    className="material-icons text-dark pointer"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#editModal"
                                                    title="編輯"
                                                >
                                                    edit
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* <div className="text-center pt-3 ">報名表已於02/14上傳，若小隊聲明書不是個別上傳，不會顯示在上傳日期</div> */}

                    <hr className="horizontal dark" />
                    <div className="d-flex flex-wrap mb-3 mt-4">
                        <div className="text-muted fs-18 fw-bold">管理小隊成員資料</div>
                        <div className="mx-auto">
                            <div id="sdgs" className="dropdown d-flex justify-content-center">
                                <button
                                    className="btn text-second bg-second dropdown-toggle mb-0"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    style={{ minWidth: '200px' }}
                                >
                                    {selectedGroup?.Groupname}
                                </button>
                                <ul className="dropdown-menu px-2 py-3" aria-labelledby="dropdownMenuButton">
                                    {groupDataList.map((group) => (
                                        <li onClick={() => setSelectedGroup(group)}>
                                            <div className="dropdown-item d-flex">{group.Groupname}</div>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div className="d-flex flex-wrap mb-3 mt-4 justify-content-between">
                                <div className="text-second mt-3">
                                    指導老師：
                                    <span>
                                        {groupteacherList?.map((i, index) => (
                                            <>
                                                {i.TeaNo}
                                                {i.TeaName}
                                                {index !== groupteacherList.length - 1 && <span> / </span>}
                                            </>
                                        ))}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className=' float-right"'>
                            <div className="btn text-second bg-second pointer mb-0 ms-auto ms-auto" data-bs-toggle="modal" data-bs-target="#editModal" onClick={() => openStudentEditModal('新增')}>
                                <span className="material-icons-outlined align-middle">add</span>
                                <span>新增小隊成員</span>
                            </div>
                        </div>
                    </div>
                    <div className="card p-4 pt-3">
                        <div className="table-responsive pt-3">
                            <table className="text-sm table table-striped mb-0">
                                <thead>
                                    <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                                        <th className="p-2 text-nowrap">學生編號</th>
                                        <th className="p-2 text-nowrap">姓名</th>
                                        <th className="p-2 text-nowrap">年級</th>
                                        <th className="p-2 text-nowrap">密碼</th>
                                        <th className="p-2 text-nowrap">備註</th>
                                        <th className="p-2 text-nowrap" style={{ width: '100px' }}>
                                            功能
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentGroupStudentList.map((student) => (
                                        <tr>
                                            <td>{student.StuNo}</td>
                                            <td>{student.StuName}</td>
                                            <td>{student.StuGrade}</td>
                                            <td>{student.Password}</td>
                                            <td>{student.Remark}</td>
                                            <td className="text-nowrap">
                                                <span className="material-icons text-dark pointer me-3" onClick={() => deleteStudent(student)} title="刪除">
                                                    delete_outline
                                                </span>
                                                <span
                                                    onClick={() => openStudentEditModal('編輯', student)}
                                                    className="material-icons text-dark pointer"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#editModal2"
                                                    title="編輯"
                                                >
                                                    edit
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <hr className="horizontal dark" />

                    <hr className="horizontal dark" />
                    <div className="pt-3 mt-4"></div>
                </div>
            </div>
            <BSModal title={'新增小隊內容'} isOpen={openErrModal} setOpenModal={setOpenErrModal}>
                {/* {errMsg} */}
            </BSModal>

            {/* 新增小隊內容  modal-start*/}
            <Modal show={isOpenEdit} onHide={() => setIsOpenEditGroup(false)}>
                <Modal.Header>
                    <Modal.Title>
                        <h5 className="">{mode}小隊</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="mx-auto ">
                    <form role="form text-left">
                        <div className="row mx-auto " id="a-m-g">
                            <div className="col-12">
                                <div>
                                    {formDataGroup.GroupNo ? (
                                        <>
                                            小隊編號：<span>{formDataGroup.GroupNo}</span>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <label htmlFor="groupName" className="fs-14">
                                    小隊名稱
                                </label>
                                <div className="input-group">
                                    <input
                                        name="小隊名稱"
                                        id="groupName"
                                        data-pattern="^(?!\s)(?=(?:[\u4E00-\u9FFF]|[a-zA-Z0-9\s]){1,8}(?![\u4E00-\u9FFF]|[a-zA-Z0-9\s])*$)(?=(?:[\u4E00-\u9FFF]|[a-zA-Z0-9]){1,15}$)"
                                        type="text"
                                        className="form-control fs-16"
                                        placeholder="不可空白，中文8個字內，中英數混用15個字為限"
                                        value={formDataGroup.Groupname}
                                        onChange={(e) => setFormDataOnChange(e, 'Groupname')}
                                    />
                                </div>
                            </div>

                            <div className="col-12 mt-3">
                                <label htmlFor="groupName" className="fs-14">
                                    競賽/學習
                                </label>
                                <div className="input-group">
                                    <select className="form-control fs-16" onChange={(e) => setFormDataOnChange(e, 'CompLearn')}>
                                        <option value="">請選擇</option>
                                        {["參加競賽", "學習,不參加競賽", "取消報名"].map((option, index) => (
                                            <option key={index} value={option} selected={option == formDataGroup?.CompLearn?.trim()}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <label className="fs-14">指導老師</label>

                                {applyInfo?.ApplyNo ? (
                                    <DropdownSelect
                                        order={0}
                                        groupNo={formDataGroup?.GroupNo}
                                        applyNo={applyInfo?.ApplyNo}
                                        target={'教師清單'}
                                        selectValue={selectTeacher1}
                                        setSelectTeacher={setSelectTeacher1}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>

                            <div className="col-12 mt-3">
                                {/* <div className="input-group">
                                    <input type="text" className="form-control fs-16" placeholder="" 
                                    value={formDataGroup.teacher2} 
                                    onChange={(e)=>setFormDataOnChange(e ,'teacher2')}/>
                                </div> */}
                                {applyInfo?.ApplyNo && selectTeacher1 ? (
                                    <DropdownSelect
                                        order={1}
                                        groupNo={formDataGroup?.GroupNo}
                                        applyNo={applyInfo?.ApplyNo}
                                        target={'教師清單'}
                                        selectValue={selectTeacher2}
                                        setSelectTeacher={setSelectTeacher2}
                                        selectTeacher1={selectTeacher1}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>

                            <div className="col-12 mt-3">
                                <label className="fs-14">備註</label>
                                <div className="input-group">
                                    <textarea
                                        rows={2}
                                        className="form-control fs-16"
                                        placeholder="最多20字"
                                        value={formDataGroup?.Remark}
                                        onChange={(e) => setFormDataOnChange(e, 'Remark')}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setIsOpenEditGroup(false)}>
                        取消
                    </button>
                    <button type="button" className="btn btn-primary" disabled={btndisable}
                        onClick={() => insertOrUpdateGroupInfo(mode)}>
                        儲存
                    </button>
                </Modal.Footer>
            </Modal>
            {/*  學生*/}
            <Modal show={isOpenEditStudent} onHide={() => setIsOpenEditStudent(false)}>
                <Modal.Header>
                    <Modal.Title>
                        <h5 className="modal-title">{mode}學生資料</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="mx-auto">
                    <form role="form text-left">
                        <div className="row mx-auto">
                            <div className="col-12">
                                <div>
                                    {formDataStudent.StuNo ? (
                                        <>
                                            學生編號：<span>{formDataStudent.StuNo}</span>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <label htmlFor="StuName" className="fs-14">
                                    姓名
                                </label>
                                <div className="input-group">
                                    <input
                                        name="姓名"
                                        id="StuName"
                                        data-colname="ss"
                                        data-pattern="^[\u4e00-\u9fa5a-zA-Z]{1,20}$"
                                        type="text"
                                        className="form-control fs-16"
                                        placeholder="不可空白，1-20字"
                                        value={formDataStudent.StuName?.trim()}
                                        onChange={(e) => setFormDataStudentOnChange(e, 'StuName')}
                                    />
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <label htmlFor="StuGrade" className="fs-14">
                                    年級
                                </label>
                                <div className="input-group">
                                    <input 
                                        name="年級"
                                        id="StuGrade"
                                        type="number"
                                        min={1}
                                        max={6}
                                        className="form-control fs-16 cursor-only"
                                        placeholder=""
                                        style={{
                                            caretColor: 'transparent'  ,
                                            cursor:"pointer"
                                                // 设置光标颜色
                                        }}
                           
                                        value={formDataStudent.StuGrade?.trim()}
                                        onChange={(e) => setFormDataStudentOnChange(e, 'StuGrade')}
                                        onKeyDown={(e) => {
                                            // 允许上下箭头和Tab键
                                            if (e.key !== "ArrowUp" && e.key !== "ArrowDown" && e.key !== "Tab") {
                                                e.preventDefault(); // 禁止其他按键输入
                                            }
                                        }}
                                    />


                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <label htmlFor="Password" className="fs-14">
                                    密碼
                                </label>
                                <div className="input-group">
                                    <input
                                        name="密碼"
                                        data-pattern="^[^\s]{6,20}$"
                                        id="Password"
                                        type="text"
                                        className="form-control fs-16"
                                        placeholder="6~20碼"
                                        value={formDataStudent?.Password?.trim()}
                                        onChange={(e) => setFormDataStudentOnChange(e, 'Password')}
                                    />
                                </div>
                            </div>

                            <div className="col-12 mt-3">
                                <label htmlFor="Remark" className="fs-14">
                                    備註
                                </label>
                                <div className="input-group">
                                    <textarea
                                        data-pattern="^.{0,20}$"
                                        name="備註"
                                        rows={2}
                                        className="form-control fs-16"
                                        placeholder="最多20字"
                                        value={formDataStudent?.Remark?.trim()}
                                        onChange={(e) => setFormDataStudentOnChange(e, 'Remark')}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setIsOpenEditStudent(false)}>
                        取消
                    </button>
                    <button type="button" className="btn btn-primary" onClick={() => insertOrUpdateStudentInfo(mode)}>
                        儲存
                    </button>
                </Modal.Footer>
            </Modal>

            <BSModal title={'欄位輸入錯誤'} isOpen={openErrModal} setOpenModal={setOpenErrModal}>
                <div dangerouslySetInnerHTML={{ __html: errMsg.replace(/\n/g, '<br>') }} />
            </BSModal>
        </>
    );
};
export default EpApplyGroup;
