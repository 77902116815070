import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import IFunData from '../../interfaces/FunData';
import IPageProps from '../../interfaces/page'
import { handleLink } from '../../modules/utils';
import { getFunDataByGroupno, getFunSourceByGroupno } from '../../services/FunDSService';
import TopNavPage1 from '../topNav1';
import TopNavPage2 from '../topNav2';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import IFunSource from '../../interfaces/FunSource';

const EpFunTextPage: React.FunctionComponent<IPageProps> = props => {
    //    type IParams={
    //        gno:string;
    //    };
    //    const {gno}=useParams<IParams>();
    const [funText, setFunText] = useState<IFunData>({
        Groupno: '',
        School: '',
        Title: '',
        Abstract: '',
        Introduction: '',
        Honor: '',
        Type: '',
        Tag: '',
        Content1: '',
        Content2: '',
        StuName1: '',
        StuName2: '',
        StuName3: '',
        StuName4: '',
        TeaName1: '',
        TeaName2: '',
        TeaName3: '',
        StuSay1: '',
        StuSay2: '',
        StuSay3: '',
        StuSay4: '',
        TeaSay1: '',
        TeaSay2: '',
        TeaSay3: '',
        ShareTitle: '',
        ShareLink: ''
    });
    const [funSource, setFunSoruce] = useState<IFunSource[]>([])

    const getTagString = (tagstr: string): string => {
        let result = ''
        const taglist = tagstr.split('/')
        for (let i = 0; i < taglist.length; i++) {
            result = result + "#" + taglist[i] + " "
        }
        return result
    }


    useEffect(() => {
        getFunDataByGroupno(sessionStorage.getItem("GroupNo")!).then(data => setFunText(data));
        getFunSourceByGroupno(sessionStorage.getItem("GroupNo")!).then(data => setFunSoruce(data));
    }, [])

    useEffect(() => {
        let content1 = document.getElementById('content1');
        if (content1 !== null) content1.innerHTML = funText.Content1

        let content2 = document.getElementById('content2');
        if (content2 !== null) content2.innerHTML = funText.Content2

    }, [funText])


    return (
        <>
            <TopNavPage1 />
            <div className="section pt-3">
                <div className="container px-3 px-md-4">
                    <div className="d-flex align-items-center justify-content-between flex-column flex-md-row">
                        <h5 className="title mb-3 me-3">青春研值文章</h5>
                        <h5 className="order-2 order-md-1 mb-3"></h5>
                        <div className="order-1 order-md-2 mb-3 text-center text-md-end" style={{ width: '232px' }}>
                            <button type="button" className="btn btn-outline-dark mb-0" onClick={() => handleLink('/fundata')}>
                                <i className="fas fa-chevron-left"></i>
                                返回 青春研值
                            </button>
                        </div>
                    </div>
                    <div className="fs-3 lh-sm fw-bolder text-center mb-5">{funText.Title}</div>
                    <div className="row align-items-center mt-4">
                        <div className="col-12 col-md-6">
                            <img className="w-100" src={"./assets/img/fun/" + funText.Groupno + ".jpg"} />
                        </div>
                        <div className="col-12 col-md-6 ps-3 ps-lg-4">
                            <div className="fw-bolder">{funText.School}</div>
                            <div className="fs-5 lh-sm fw-bolder text-dblue my-3">{funText?.Honor}</div>
                            <div className="">類別：{funText?.Type}({funText?.Groupno})</div>
                            <div>作者：
                                {(funText.StuName1 !== null) ?
                                    <OverlayTrigger trigger="hover" placement="bottom"
                                        overlay={
                                            <Popover>
                                                <Popover.Header><div className='text-center'>{funText.StuName1.trim()}同學的話</div></Popover.Header>
                                                <Popover.Body><div className='text-sm text-justify' dangerouslySetInnerHTML={{ __html: funText.StuSay1}}></div></Popover.Body>
                                            </Popover>
                                        }>
                                        <span className="pointer text-second fw-bolder">{funText.StuName1 + ((funText.StuName2=== null)? "" :"、")}</span>
                                    </OverlayTrigger> : null
                                }
                                {(funText.StuName2 !== null) ?
                                    <OverlayTrigger trigger="hover" placement="bottom"
                                        overlay={
                                            <Popover>
                                                <Popover.Header><div className='text-center'>{funText.StuName2.trim()}同學的話</div></Popover.Header>
                                                <Popover.Body><div className='text-sm text-justify' dangerouslySetInnerHTML={{ __html: funText.StuSay2}}></div></Popover.Body>
                                            </Popover>
                                        }>
                                        <span className="pointer text-second fw-bolder">{funText.StuName2 + ((funText.StuName3=== null)? "" :"、")}</span>
                                    </OverlayTrigger> : null
                                }
                                {(funText.StuName3 !== null) ?
                                    <OverlayTrigger trigger="hover" placement="bottom"
                                        overlay={
                                            <Popover>
                                                <Popover.Header><div className='text-center'>{funText.StuName3.trim()}同學的話</div></Popover.Header>
                                                <Popover.Body><div className='text-sm text-justify' dangerouslySetInnerHTML={{ __html: funText.StuSay3}}></div></Popover.Body>
                                            </Popover>
                                        }>
                                        <span className="pointer text-second fw-bolder">{funText.StuName3 + ((funText.StuName4=== null)? "" :"、")}</span>
                                    </OverlayTrigger> : null
                                }
                                {(funText.StuName4 !== null) ?
                                    <OverlayTrigger trigger="hover" placement="bottom"
                                        overlay={
                                            <Popover>
                                                <Popover.Header><div className='text-center'>{funText.StuName4.trim()}同學的話</div></Popover.Header>
                                                <Popover.Body><div className='text-sm text-justify' dangerouslySetInnerHTML={{ __html: funText.StuSay4}}></div></Popover.Body>
                                            </Popover>
                                        }>
                                        <span className="pointer text-second fw-bolder">{funText.StuName4}</span>
                                    </OverlayTrigger> : null
                                }
                            </div>

                            <div>指導老師：
                                {(funText.TeaName1 !== null) ?
                                    <OverlayTrigger trigger="hover" placement="bottom"
                                        overlay={
                                            <Popover>
                                                <Popover.Header><div className='text-center'>{funText.TeaName1.trim()}老師的話</div></Popover.Header>
                                                <Popover.Body><div className='text-sm text-justify' dangerouslySetInnerHTML={{ __html: funText.TeaSay1}}></div></Popover.Body>
                                            </Popover>
                                        }>
                                        <span className="pointer text-second fw-bolder">{funText.TeaName1 + ((funText.TeaName2=== null)? "" :"、")}</span>
                                    </OverlayTrigger> : null
                                }
                                {(funText.TeaName2 !== null) ?
                                    <OverlayTrigger trigger="hover" placement="bottom"
                                        overlay={
                                            <Popover>
                                                <Popover.Header><div className='text-center'>{funText.TeaName2.trim()}老師的話</div></Popover.Header>
                                                <Popover.Body><div className='text-sm text-justify' dangerouslySetInnerHTML={{ __html: funText.TeaSay2}}></div></Popover.Body>
                                            </Popover>
                                        }>
                                        <span className="pointer text-second fw-bolder">{funText.TeaName2 + ((funText.TeaName3=== null)? "" :"、")}</span>
                                    </OverlayTrigger> : null
                                }
                                {(funText.TeaName3 !== null) ?
                                    <OverlayTrigger trigger="hover" placement="bottom"
                                        overlay={
                                            <Popover>
                                                <Popover.Header><div className='text-center'>{funText.TeaName3.trim()}老師的話</div></Popover.Header>
                                                <Popover.Body><div className='text-sm text-justify' dangerouslySetInnerHTML={{ __html: funText.TeaSay3}}></div></Popover.Body>
                                            </Popover>
                                        }>
                                        <span className="pointer text-second fw-bolder">{funText.TeaName3}</span>
                                    </OverlayTrigger> : null
                                }
                            </div>
                            {(funText.ShareTitle !==null) ?
                            <>
                                <div>轉載分享：<a href={funText.ShareLink} target="_blank" >{funText.ShareTitle}</a></div>
                                <div className="text-dblue text-end">{getTagString(funText.Tag)}</div>
                            </> : null
                            }
                        </div>

                    </div>
                    <div className="card text-dblue text-justify p-2 my-5">
                        <div className="alert alert-blue px-4">
                            <div className="fs-5 fw-bolder mb-2">摘要</div>
                            <p className="fs-6 text-dblue mx-0 mx-md-5">
                                {funText.Abstract}
                            </p>
                        </div>
                        <div className="px-2 px-md-4" id='content1'>
                         
                        </div>
                        <div className="fun-subitem px-2 px-md-4">二、探究架構圖</div>
                        <div className="rounded-3 mx-auto p-3 imgborder text-center">
                            <div className="d-flex justify-content-center align-items-start">

                                <img src={"./assets/img/fun/chart/" + funText.Groupno + ".jpg"} alt="" className="align-text-top w-100 imgs"
                                    style={{ maxWidth: '90%' }} />

                            </div>
                        </div>
                        <div className="px-2 px-md-4" id='content2'>

                        </div>
                        <div className="fun-item px-2 px-md-4">伍、參考資料</div>
                        <ol style={{ marginLeft: '15px' }}>
                            {
                                funSource.map((item) => {
                                    return <li><a href={item.url} target="_blank" >{item.Source}</a></li>
                                })
                            }

                        </ol>
                    </div>

                </div>
            </div>
            {/* <div className="imgPreview">
                <img src="#" alt="" id="imgPreview" />
            </div> */}


        </>
    );
}

export default EpFunTextPage;