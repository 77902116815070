import React, { useEffect, useState } from 'react';
import { IApplyFormData, IApplyTopic, IGroupInfo, IGroupStudent, IOpinion, ISearchDataSummary, TeacherGroupInfo } from '../../../interfaces/EpModal';
import { getEPApplyByNo } from '../../../services/EP/EpLoginService';
import { getDrGroupPrimeByYrApplyNo, getDrStudentByGroup } from '../../../services/EP/EPApplyGroupService';
import { Export2Word, ReacherArPictureName_before, currentClass, currentTempGroup, downloadImage, formatDate, formatDateTime, getFileNameString, getLoginInfo, modeGlobal, replaceNewline, showJsonObj } from '../tool/helper';
import { getOpinionByGroupNo, getSubSummarySubCollectByGroupNo, getSubTopicByGroupNo } from '../../../services/EP/EpApplyExploreService';
import { getuploadtime } from '../../../services/DrFileService';

interface IProps {

    propClassName: string
    propGroupNo: string | undefined
    propIsSectionCtrl?: boolean
    propSectionCtrlList?: string[]
    role?: 'student' | 'teacher' | 'apply'
    isHidden?: boolean
    opinion?: IOpinion
    selectGroup?: TeacherGroupInfo | any
}


/**epApplyHome.html  【6-4-5	小隊基本資料】*/
const AllOpinion: React.FunctionComponent<IProps> = (props) => {
    const { propClassName, propGroupNo, propIsSectionCtrl, propSectionCtrlList, role } = props;
    const [opinion, setOpinion] = useState<IOpinion>();
    /**  */
    const [subTopicBeforeFeedback, setSubTopicBeforeFeedback] = useState<IApplyTopic[]>();
    const [subTopicAfterFeedback, setSubTopicAfterFeedback] = useState<IApplyTopic[]>();
    const [searchDataList, setSearchDataList] = useState<ISearchDataSummary[]>();
    const [groupDataList, setGroupDataList] = useState<IGroupInfo[]>([]);
    const [applyInfo, setApplyInfo] = useState<IApplyFormData>();
    /** 目前選得隊伍 */
    const [selectedGroup, setSelectedGroup] = useState<IGroupInfo | any>();
    /** 目前選擇的隊伍明細 */
    const [currentGroupStudentList, setCurrentGroupStudentList] = useState<IGroupStudent[]>([]);
    const [currentLoginInfo, setCurrentLoginInfo] = useState<any>();
    const [uploadtime ,setUploadtime ] =useState<string>("")
    useEffect(() => {
        const fetchData = async () => {
            let groupList = await fetchDataGroup();
            setSelectedGroup(groupList[0]);
        };
        getEPApplyByLoginID(sessionStorage.getItem('LoginID') + '');
        fetchData();
        if (selectedGroup) {
            fetchDataGroupStudent(selectedGroup.GroupNo);
        }
        // 取得檔案資訊
    }, [selectedGroup]);
        const getuploadtimes  = async () => {
        if(propGroupNo){
            const rsp= await getuploadtime(`/upload${currentClass}/${propGroupNo}`, 'report.pptx') as  string
           const  rs = JSON.parse(rsp)
            if(rsp){
                setUploadtime(rs.time||'')


            }
        }

    }

    useEffect(() => {
        if (!props.opinion) {
            fetchDataOpinion();
        } else {
            setOpinion(props.opinion)
        }
        //取得 子題
        fetchDataSubTopic();

        fetchDataSubSummarySubCollectByGroupNo();

        setSelectedGroup(props.selectGroup);
    }, [selectedGroup, propGroupNo, props.selectGroup]);


    useEffect(() => {
        // alert("sss"+JSON.stringify(propGroupNo))
        //取得opinion 
        if (!props.opinion) {
            fetchDataOpinion();
        }
        //取得 子題
        fetchDataSubTopic();

        fetchDataSubSummarySubCollectByGroupNo();
        getuploadtimes()

    }, [selectedGroup, propGroupNo]);


    useEffect(() => {
        //取得opinion
        fetchDataOpinion();
        //取得 子題
        fetchDataSubTopic();

        fetchDataSubSummarySubCollectByGroupNo();
    }, [selectedGroup, propGroupNo]);

    const isDisplay = (sectionName: string) => {
        //  沒有受控制 或是 可以直接
        return !propIsSectionCtrl || (propIsSectionCtrl && propSectionCtrlList?.includes(sectionName))
    }

    /** */
    const showForStudentRole = (): boolean => {
        return role === 'student'
    }
    const getStart = (num: string) => {
        let number = parseInt(num);
        if (number === 5) {
            return '★★★★★';
        }
        if (number === 4) {
            return '★★★★';
        }
        if (number === 3) {
            return '★★★';
        }
        if (number === 2) {
            return '★★';
        }
        if (number === 1) {
            return '★';
        }
        return '';
    };

    const fetchDataOpinion = async () => {
        if (propGroupNo) {
            // alert('propGroupNo'+JSON.stringify(propGroupNo)) //Jean
            let opinion = (await getOpinionByGroupNo(propGroupNo)) as IOpinion[];
            console.log("opinion",opinion)
            setOpinion(opinion[0]);
        }
    };
    /** 取得子題資料*/
    const fetchDataSubTopic = async () => { 
        if (propGroupNo) {

            //取得回饋前 子題
            let subTopicbefore = (await getSubTopicByGroupNo(0, propGroupNo)) as IApplyTopic[];

            console.log('subTopicbefore ', subTopicbefore);
            setSubTopicBeforeFeedback(subTopicbefore);

            //取得回饋後子題
            let subTopicAfter = (await getSubTopicByGroupNo(1, propGroupNo)) as IApplyTopic[];

            console.log('subTopicAfter ', subTopicAfter);
            setSubTopicAfterFeedback(subTopicAfter);
        }
    };

    const fetchDataSubSummarySubCollectByGroupNo = async () => {
        if (propGroupNo) {

            let rsp = (await getSubSummarySubCollectByGroupNo(1, propGroupNo)) as ISearchDataSummary[];
            setSearchDataList(rsp || []);
            console.log("setSearchDataList ", rsp);
            // alert('JSJ'+JSON.stringify(rsp))
        }
    };

    const fetchDataGroupStudent = async (groupNo: string) => {
        /**取得學生資料 */
        let groupStudentList = (await getDrStudentByGroup(groupNo)) as IGroupStudent[];
        setCurrentGroupStudentList(groupStudentList);
        console.log('groupStudentList...', groupStudentList);
    };

    /**  取資料 */
    const fetchDataGroup = async (): Promise<IGroupInfo[]> => {
        // alert('feach Data');
        let currentLoginInfo = getLoginInfo();
        setCurrentLoginInfo(currentLoginInfo);
        if(currentLoginInfo.LoginStatus==="DrApply"){
            let GroupDataList = (await getDrGroupPrimeByYrApplyNo(propClassName, currentLoginInfo.LoginID)) as IGroupInfo[];
        setGroupDataList(GroupDataList);
        // setTeacherDataList(teacherDataList as ITearcherInfo[])
        console.log(' feachData groupDataList', groupDataList);
        return GroupDataList;
    }else {

        return []
    }
   
    };

    /** 取得登入者基本資訊 (申請者)*/
    const getEPApplyByLoginID = async (loginID: string) => {
        let rsp = await getEPApplyByNo(loginID);
        console.log('rsp', rsp[0]);
        setApplyInfo(rsp[0] as IApplyFormData);
    };

    /** */
    const genRefSourcePrint = () => {

        let num = 1
        return (

            <div id="refer_source" hidden  >
                <br />
                參考資料
                {/* {JSON.stringify(props.isHidden)} */}
                <table className="text-sm table table-striped mb-0">
                    <thead>
                        <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                            <th className="p-2 text-nowrap">No</th>
                            <th className="p-2 text-nowrap">網站</th>
                            <th className="p-2 text-nowrap">網址</th>
                            <th className="p-2 text-nowrap">日期</th>
                        </tr>
                    </thead>
                    <tbody>
                        {

                            searchDataList?.map(i => i.Summaries.map(x => (
                                x.Collects.map(y => (
                                    <>
                                        <tr>
                                            <td className="p-2 text-nowrap">{num++}</td>
                                            <td className="p-2 text-nowrap">{y.Source}</td>
                                            <td className="p-2 text-nowrap">{y.Web}</td>
                                            <td className="p-2 text-nowrap">{y.SourceUpdateDate}</td>

                                        </tr>
                                    </>
                                ))
                            )))}

                    </tbody>

                </table>
            </div>)


    }

    return (
        < >
            <div id="title-info" hidden>
                {selectedGroup?.Schoolname || selectedGroup?.SchoolName}{selectedGroup?.Groupname || selectedGroup?.GroupName}({selectedGroup?.GroupNo})
            </div>
            <div className="accordion" id="accordionRental" hidden={props.isHidden}>
                <div id="print-section-all">
                    {isDisplay('level1') ? (<div className="accordion-item ">
                        <div className="card bd-callout bd-callout-danger">
                            <button className="accordion-button text-dark p-0" type="button" data-bs-toggle="collapse" data-bs-target="#level1" aria-expanded="true" aria-controls="level1">
                                <h4 className="my-0 me-3">階段1、決定探究題目(回饋前)</h4>

                                <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                            </button>
                            <div className="d-flex flex-wrap z-index-3 position-absolute" style={{ left: '350px' }}>
                                <div className="btn text-second bg-second mb-0 me-3 pointer" data-bs-toggle="modal" data-bs-target="#teach1">
                                    怎樣選擇題目
                                </div>
                                <div className="btn text-second bg-second mb-0 pointer" onClick={() => Export2Word('print-section-1', '階段1、決定探究題目(回饋前)', '')}>
                                    匯出檔案
                                </div>
                            </div>
                        </div>
                        {/* LEVEL1 */}
                        <div className="collapse show" id="level1" data-bs-parent="#accordionRental">
                            <div className="card p-4 pt-3" id="print-section-1">
                                <h5 className="fw-bolder pt-3">1-1 提出探究題目</h5>
                                <div className="table-responsive pt-3">
                                    <table className="text-sm table table-striped mb-0">
                                        <thead>
                                            <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                                                <th className="p-2 text-nowrap">成員</th>
                                                <th className="p-2 text-nowrap">我想探究的題目</th>
                                                <th className="p-2 text-nowrap">想探究這個題目的理由</th>
                                                <th className="p-2 text-nowrap">日期</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {opinion?.Pre11.map((item) => (
                                                <tr>
                                                    <td className="text-nowrap">{item.StuName} </td>
                                                    <td>{item.DataA}</td>
                                                    <td>{item.DataB}</td>
                                                    <td> {formatDate(item.UpdateDate)}</td>
                                                </tr>
                                            ))}


                                        </tbody>
                                    </table>
                                </div>

                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">1-2 決定探究題目</h5>
                                <div className="p-3">
                                    <div className="fw-bolder text-second pb-2">
                                        我們決定探究的題目：
                                        <span className="text-primary">{opinion?.Pre12_DataA} </span>
                                    </div>
                                    <div className="fw-bolder text-second pt-3 pb-2">決定原因：</div>

                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre12_DataB || '') }}></div>
                                    <div className="pt-3 pb-2">
                                        <span className="fw-bolder text-second">日期：</span>
                                        <span> {formatDateTime(opinion?.Pre12_UpdateDate)} </span>
                                    </div>
                                </div>
                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">1-3 預測可能的結果</h5>
                                <div className="p-3">
                                    <div className="fw-bolder text-second pb-2">我們預測可能的結果是：</div>
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre13_DataB || '') || '' }}></div>
                                    <div className="pt-3 pb-2">
                                        <span className="fw-bolder text-second">日期：</span>
                                        <span>{formatDateTime(opinion?.Pre13_UpdateDate)}</span>
                                    </div>
                                </div>

                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">1-4 困難、解決與新學習</h5>
                                <div className="p-3">
                                    <div className="fw-bolder text-second pb-2">我們在「階段1」遇到的困難：</div>
                                    <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre14_DataA + '') || '' }}></div>
                                    <div className="fw-bolder text-second pt-3 pb-2">我們最後如何解決：</div>
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre14_DataB || '') }}></div>
                                    <div className="fw-bolder text-second pt-3 pb-2">我們學到的新思維：</div>
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre14_DataC || '') }}></div>
                                    <div className="pt-3 pb-2">
                                        <span className="fw-bolder text-second">日期：</span>
                                        <span>{formatDateTime(opinion?.Pre14_UpdateDate)}</span>
                                    </div>
                                </div>
                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">階段1，指導老師留言</h5>
                                <div className="p-3">
                                    <div className="fw-bolder text-second pb-2">老師留言：</div>
                                    <div dangerouslySetInnerHTML={{ __html: opinion?.Pre19_DataA || '' }}></div>
                                    <div className="pt-3 pb-2">
                                        <span className="fw-bolder text-second">日期：</span>
                                        <span>{formatDateTime(opinion?.Pre19_UpdateDate)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>) : (<></>)}
                    {isDisplay('level2') ? (
                        <div className="accordion-item">
                            <div className="card bd-callout bd-callout-danger">
                                <button
                                    className="accordion-button text-dark collapsed p-0"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#level2"
                                    aria-expanded="false"
                                    aria-controls="level2"
                                >
                                    <h4 className="my-0 me-2">階段2、規劃探究架構(回饋前)</h4>

                                    <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                    <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                </button>
                                <div className="d-flex flex-wrap z-index-3 position-absolute" style={{ left: '350px' }}>
                                    <div className="btn text-second bg-second mb-0 me-3 pointer" data-bs-toggle="modal" data-bs-target="#teach2">
                                        怎樣規劃探究架構
                                    </div>
                                    <div className="btn text-second bg-second mb-0 pointer" onClick={() => Export2Word('print-section-2', '階段2、規劃探究架構(回饋前)', getFileNameString(selectedGroup, undefined))}>
                                        匯出檔案
                                    </div>
                                </div>
                            </div>
                            <div id="level2" className="collapse" data-bs-parent="#accordionRental">
                                <div id="print-section-2" className="card p-4 pt-3">
                                    <h5 className="fw-bolder pt-3">2-1 擬定探究子題</h5>
                                    <div className="d-flex flex-wrap align-items-end">
                                        <div className="fw-bolder text-second pt-3 pb-2">
                                            本隊探究題目：
                                            <span className="text-primary">{<div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post12_DataA || '') }}></div>}</span>
                                        </div>
                                    </div>
                                    <div className="table-responsive pt-3">
                                        <table className="text-sm table table-striped">
                                            <thead>
                                                <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                                                    <th className="p-2">#</th>
                                                    <th className="p-2 text-nowrap">子題名稱</th>
                                                    <th className="p-2 text-nowrap">負責隊員</th>
                                                    <th className="p-2 text-nowrap">日期</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {subTopicBeforeFeedback?.map((subTopic) => (
                                                    <tr>
                                                        <td>{subTopic.SubNo}</td>
                                                        <td> {subTopic.SubName}</td>
                                                        <td className="text-nowrap">{subTopic.Description}</td>
                                                        <td>{formatDate(subTopic.UpdateDate)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3 print-remove">2-2 繪製探究架構</h5>
                                    <div className="rounded-3 mx-auto p-3 imgborder text-center print-remove">
                                        <div className="d-flex justify-content-center align-items-start">
                                            <img 
                                                className="print-remove imgs"
                                                src={`/uploads/upload${propClassName}/${opinion?.GroupNo}/${ReacherArPictureName_before}`}
                                                alt=""
                                                width="500"
                                                // onClick={() => downloadImage(`/uploads/upload${propClassName}/${opinion?.GroupNo}/${ReacherArPictureName_before}`, '研究架構.png')}
                                            />
                                        </div>
                                        <button 
                                         onClick={() => downloadImage(`/uploads/upload${propClassName}/${opinion?.GroupNo}/${ReacherArPictureName_before}`, '研究架構.png')}                                                        type="button" 
                                                        className="btn btn-secondary" 
                                                        data-bs-dismiss="modal" 
                                                     >
                                                     下載架構圖
                                                     </button>
                                    </div>

                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">2-3 困難、解決與新學習</h5>
                                    <div className="p-3">
                                        <div className="fw-bolder text-second pb-2">我們在「階段2」遇到的困難：</div>

                                        {/* pre23 */}

                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre24_DataA || '--') }}></div>
                                        <div className="fw-bolder text-second pt-3 pb-2">我們最後如何解決：</div>

                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre24_DataB || '') }}></div>
                                        <div className="fw-bolder text-second pt-3 pb-2">我們學到的新思維：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre24_DataC || '') }}></div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{formatDateTime(opinion?.Pre24_UpdateDate)}</span>
                                        </div>
                                    </div>
                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">階段2，指導老師留言</h5>
                                    <div className="p-3">
                                        <div className="fw-bolder text-second pb-2">老師留言：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre29_DataA || '--') }}>{/* 學校裡的新住民子女學生人數大約在70人上下 */}</div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{formatDateTime(opinion?.Pre29_UpdateDate)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (<></>)}
                    {isDisplay('levelex') ? (
                        <div className="accordion-item">
                            <div className="card bd-callout bd-callout-danger">
                                <button
                                    className="accordion-button text-dark collapsed p-0"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#levelex"
                                    aria-expanded="false"
                                    aria-controls="levelex"
                                >
                                    <h4 className="my-0 me-2">專家學者線上回饋</h4>
                                    <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                    <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="collapse" id="levelex" data-bs-parent="#accordionRental">
                                <div className="card p-4 pt-3">
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.ExpoOpinion || '--') }}>{/* (專家學者尚未回饋) */}</div>
                                </div>
                            </div>
                        </div>
                    ) : (<></>)}


                    {isDisplay('level1b') ? (

                        <div className="accordion-item">
                            <div className="card bd-callout bd-callout-danger">
                                <button
                                    className="accordion-button text-dark collapsed p-0"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#level1b"
                                    aria-expanded="false"
                                    aria-controls="level1b"
                                >
                                    <h4 className="my-0 me-3">階段1、決定探究題目(回饋後)</h4>

                                    <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                    <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                </button>
                                <div className="d-flex flex-wrap z-index-3 position-absolute" style={{ left: '350px' }}>
                                    <div className="btn text-second bg-second mb-0 pointer" onClick={() => Export2Word('print-section-1-post', '階段1、決定探究題目(回饋後)', getFileNameString(selectedGroup, undefined))}>
                                        匯出檔
                                    </div>
                                </div>
                            </div>

                            <div className="collapse" id="level1b" data-bs-parent="#accordionRental">
                                <div className="card p-4 pt-3" id="print-section-1-post">
                                    <h5 className="fw-bolder pt-3">1-1 提出探究題目</h5>
                                    <div className="d-flex flex-wrap align-items-end"></div>
                                    <div className="table-responsive pt-3">
                                        <table className="text-sm table table-striped">
                                            <thead>
                                                <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                                                    <th className="p-2 text-nowrap">成員</th>
                                                    <th className="p-2 text-nowrap">我想探究的題目</th>
                                                    <th className="p-2 text-nowrap">想探究這個題目的理由</th>
                                                    <th className="p-2 text-nowrap">日期</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {opinion?.Post11.map((item) => (
                                                    <tr>
                                                        <td className="text-nowrap">{item.StuName}</td>
                                                        <td>{item.DataA}</td>
                                                        <td>{item.DataB}</td>
                                                        <td> {formatDate(item.UpdateDate)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">1-2 決定探究題目</h5>
                                    <div className="p-3">
                                        <div className="fw-bolder text-second pb-2">
                                            我們決定探究的題目(回饋前)：
                                            <span className="text-primary">{<div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre12_DataA || '') }}></div>}</span>
                                        </div>
                                        <div className="fw-bolder text-second pb-2">
                                            我們決定探究的題目(回饋後)：
                                            <span className="text-primary">{<div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post12_DataA || '') }}></div>}</span>
                                        </div>
                                        <div className="fw-bolder text-second pt-3 pb-2">決定原因：</div>

                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post12_DataA || '') }}></div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            {formatDate(opinion?.Post12_UpdateDate)}
                                            <span></span>
                                        </div>
                                    </div>
                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">1-3 預測可能的結果</h5>
                                    <div className="p-3">
                                        <div className="fw-bolder text-second pb-2">我們預測可能的結果是：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post13_DataB || '') }}></div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{formatDateTime(opinion?.Post13_UpdateDate)}</span>
                                        </div>
                                    </div>

                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">1-4 困難、解決與新學習</h5>
                                    <div className="p-3">
                                        <div className="fw-bolder text-second pb-2">我們在「階段1」遇到的困難：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post14_DataA || '--') }}></div>
                                        <div className="fw-bolder text-second pt-3 pb-2">我們最後如何解決：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post14_DataB || '--') }}></div>
                                        <div className="fw-bolder text-second pt-3 pb-2">我們學到的新思維：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post14_DataC || '--') }}></div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{formatDateTime(opinion?.Post14_UpdateDate)}</span>
                                        </div>
                                    </div>
                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">階段1，指導老師留言</h5>
                                    <div className="p-3">
                                        <div className="fw-bolder text-second pb-2">老師留言：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post19_DataA || '--') }}></div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{formatDateTime(opinion?.Post19_UpdateDate)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (<></>)}

                    {isDisplay('level2b') ? (
                        <div className="accordion-item">
                            <div className="card bd-callout bd-callout-danger">
                                <button
                                    className="accordion-button text-dark collapsed p-0"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#level2b"
                                    aria-expanded="false"
                                    aria-controls="level2b"
                                >
                                    <h4 className="my-0 me-2">階段2、規劃探究架構(回饋後)</h4>

                                    <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                    <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                </button>
                                <div className="d-flex flex-wrap z-index-3 position-absolute" style={{ left: '350px' }}>
                                    <div className="btn text-second bg-second mb-0 pointer" onClick={() => Export2Word('print-section-2-post', '階段2、規劃探究架構(回饋後)', getFileNameString(selectedGroup, undefined))}>
                                        匯出檔案
                                    </div>
                                </div>
                            </div>
                            <div className="collapse" id="level2b" data-bs-parent="#accordionRental">
                                <div className="card p-4 pt-3" id="print-section-2-post">
                                    <h5 className="fw-bolder pt-3">2-1 擬定探究子題</h5>
                                    <div className="d-flex flex-wrap align-items-end">
                                        <div className="fw-bolder text-second pt-3 pb-2">
                                            本隊探究題目： <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post12_DataA || '--') }}></div>
                                        </div>
                                    </div>
                                    <div className="table-responsive pt-3">
                                        <table className="text-sm table table-striped">
                                            <thead>
                                                <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                                                    <th className="p-2">#</th>
                                                    <th className="p-2 text-nowrap">子題名稱</th>
                                                    <th className="p-2 text-nowrap">負責隊員</th>
                                                    <th className="p-2 text-nowrap">日期</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {subTopicAfterFeedback?.map((subTopic) => (
                                                    <tr>
                                                        <td>{subTopic.SubNo}</td>
                                                        <td> {subTopic.SubName}</td>
                                                        <td className="text-nowrap">{subTopic.Description}</td>
                                                        <td>{formatDate(subTopic.UpdateDate)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">2-2 繪製探究架構SS</h5>
                                    {/* {`/uploads/upload${propClassName}/${opinion?.GroupNo}/${ReacherArPictureName_before}`} */}
                                    <div className="rounded-3 mx-auto p-3 imgborder text-center">
                                        <div className="d-flex justify-content-center align-items-start">
                                            <img
                                                className="print-remove imgs"
                                                src={`/uploads/upload${propClassName}/${opinion?.GroupNo}/${ReacherArPictureName_before}`} alt="" width="500"
                                               />
                                        </div>
                                        <button 
                                        onClick={() => downloadImage(`/uploads/upload${propClassName}/${opinion?.GroupNo}/${ReacherArPictureName_before}`, '研究架構.png')} 
                                                          type="button" 
                                                        className="btn btn-secondary" 
                                                        data-bs-dismiss="modal" 
                                                     >
                                                     下載架構圖
                                                     </button>

                                    </div>

                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">2-3 困難、解決與新學習</h5>
                                    <div className="p-3">
                                        <div className="fw-bolder text-second pb-2">我們在「階段2」遇到的困難：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post24_DataA || '--') }}></div>
                                        <div className="fw-bolder text-second pt-3 pb-2">我們最後如何解決：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post24_DataB || '--') }}></div>
                                        <div className="fw-bolder text-second pt-3 pb-2">我們學到的新思維：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post24_DataC || '--') }}></div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{formatDateTime(opinion?.Post24_UpdateDate)}</span>
                                        </div>
                                    </div>
                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">階段2，指導老師留言</h5>
                                    <div className="p-3">
                                        <div className="fw-bolder text-second pb-2">老師留言：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post29_DataA || '--') }}></div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{formatDateTime(opinion?.Post29_UpdateDate)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (<></>)}

                    {isDisplay('level3') ? (
                        <div className="accordion-item">
                            <div className="card bd-callout bd-callout-danger">
                                <button
                                    className="collapsed accordion-button text-dark p-0"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#level3"
                                    aria-expanded="false"
                                    aria-controls="level3"
                                >
                                    <h4 className="my-0 me-3">階段3、蒐尋瀏覽資料</h4>
                                    <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                    <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                </button>
                                {showForStudentRole() ?
                                    (<div>
                                        <div className="text-second font-weight-bold">進行階段3和4之前，請先至「階段1和2(回饋後)」頁面，完成資料的確認和填寫是否修改的原因。</div>

                                        <div>
                                            <div>網路像大海一樣浩瀚無邊，要學習「有目的」的蒐尋資料。</div>
                                            <div>各隊訂的「子題」就是初步的「目的」，試著下關鍵字詞，看看找到什麼。</div>
                                            <div>選擇資料請記得，要判斷該筆資料的可信度。</div>
                                            <div>並且，不要整段複製貼上，消化整理過的才算真正讀通。</div>
                                        </div></div>) : (<></>)}

                                <div className="d-flex flex-wrap z-index-3 position-absolute" style={{ left: '280px' }}>
                                    <div className="btn text-second bg-second mb-0 me-3 pointer" data-bs-toggle="modal" data-bs-target="#teach3">
                                        怎樣蒐集資料
                                    </div>
                                    <div className="btn text-second bg-second mb-0 me-3 pointer" data-bs-toggle="modal" data-bs-target="#teach3-2">
                                        怎樣判斷資料的可信度
                                    </div>
                                    <div className="btn text-second bg-second mb-0 pointer" onClick={() => Export2Word('print-section-3', '階段3、蒐尋瀏覽資料', getFileNameString(selectedGroup, undefined))}>
                                        匯出檔案
                                    </div>
                                </div>
                            </div>

                            <div className="collapse" id="level3" data-bs-parent="#accordionRental">
                                <div className="card p-4 pt-3" id="print-section-3">
                                    <h5 className="fw-bolder pt-3">3-1 運用關鍵字詞蒐尋</h5>
                                    <div className="fw-bolder text-second pb-2">
                                        本隊探究題目：
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post12_DataA || '--') }}></div>
                                    </div>
                                    <div className="table-responsive pt-3">
                                        <table className="text-sm table table-striped">
                                            <thead>
                                                <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                                                    <th className="p-2">#</th>
                                                    <th className="p-2 text-nowrap">子題名稱</th>
                                                    <th className="p-2 text-nowrap">負責隊員</th>
                                                    <th className="p-2 text-nowrap">關鍵字詞</th>
                                                    <th className="p-2 text-nowrap">日期</th>
                                                    {showForStudentRole() ? (<th className="p-2 text-nowrap ">功能</th>) : (<></>)}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {subTopicAfterFeedback?.map((subTopic) => (
                                                    <tr>
                                                        <td>{subTopic.SubNo}</td>
                                                        <td> {subTopic.SubName}</td>
                                                        <td className="text-nowrap">{subTopic.Description}</td>
                                                        <td className="text-nowrap">{subTopic.Keyword}</td>
                                                        <td>{formatDateTime(subTopic.UpdateDate)}</td>
                                                        {showForStudentRole() ? (<td className="text-nowrap">
                                                            <span className="material-icons text-dark pointer" data-bs-toggle="modal"
                                                                data-bs-target="#editModal3-1" title="編輯">edit</span>
                                                        </td>) : (<></>)}
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>

                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">3-2 蒐集瀏覽資料</h5>

                                    {showForStudentRole() ? (
                                        <ul>
                                            <li>請列出探究每個子題時所蒐集的資料，資料筆數不限。</li>
                                            <li>蒐集的資料內容，請盡量用條列式整理重點。</li>
                                            <li>請記錄資料來源，如果是網站，請列出網站名稱及標題和網址；如果是紙本資料，請列出作者/年份/篇名/書名。</li>
                                            <li>請以1~5顆星判斷資料的可信度，並簡單寫出理由。</li>
                                            <li>輸入結束，可按【重整並查看已輸入的瀏覽資料】看輸入結果。</li>
                                            <li>這裡全體隊員均可編輯。</li>
                                        </ul>

                                    ) : (<></>)}
                                    <div className="accordion" id="searchData">
                                        <div className="p-3">
                                            {searchDataList?.map((searchData) => (
                                                <div className="accordion-item">
                                                    <button
                                                        className="collapsed accordion-button p-0 border-bottom mb-2"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse1"
                                                        aria-expanded="false"
                                                        aria-controls="collapse1"
                                                    >
                                                        <div className="fw-bolder text-second p-2">
                                                        子題{searchData.SubNo},{searchData.SubName}{' '}
                                                        </div>
                                                        <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-18 me-3" aria-hidden="true"></i>
                                                        <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-18 me-3" aria-hidden="true"></i>
                                                    </button>

                                                    <div id="collapse1" className="accordion-collapse bg-white border-bottom collapse" aria-labelledby="heading1" data-bs-parent="#searchData">
                                                        <div className="accordion-body">
                                                            <div className="table-responsive">
                                                                <table className="table table-striped mb-0">
                                                                    <thead>
                                                                        <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                                                                            <th className="p-2 text-nowrap">重點整理</th>
                                                                            <th className="p-2 text-nowrap">資料來源</th>
                                                                            {showForStudentRole() ? (<th className="p-2 text-nowrap print-remove">功能</th>) : (<></>)}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {searchData.Summaries.map((question, index) => (
                                                                            <tr>
                                                                                <td className="text-wrap">
                                                                                    <div className="my-2" dangerouslySetInnerHTML={{ __html: replaceNewline(question.Summary || '') }}></div>
                                                                                </td>
                                                                                <td className="text-wrap">
                                                                                    {question.Collects.map((source) => (
                                                                                        <div className="my-2">
                                                                                            <a href={source.Web} target="_blank" className="text-dgreen">
                                                                                                {source.Source}
                                                                                            </a>
                                                                                            <div>可信度：{getStart(source.Credibility)}</div>
                                                                                            <div>{source.Reason}</div>
                                                                                        </div>
                                                                                    ))}

                                                                                </td>
                                                                                <td className="text-nowrap print-remove">
                                                                                    <span className="material-icons text-dark pointer me-3"
                                                                                        onClick={() => { }} title="刪除">delete_outline</span>
                                                                                    <span className="material-icons text-dark pointer" data-bs-toggle="modal"
                                                                                        data-bs-target="#editModal3-2" title="編輯">edit</span>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>

                                                                {/*  */}
{/* 
                                                                <table id="ref-source" className="table table-striped mb-0" hidden>
                                                                    <thead>
                                                                        <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                                                                            <th className="p-2 text-nowrap">重點整理</th>
                                                                            <th className="p-2 text-nowrap">資料來源</th>
                                                                            <th className="p-2 text-nowrap">日期</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody></tbody>
                                                                </table> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">3-3 困難、解決與新學習</h5>
                                    <div className="p-3">
                                        <div className="fw-bolder text-second pb-2">我們在「階段3」遇到的困難：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post33_DataA || '--') }}></div>

                                        <div className="fw-bolder text-second pt-3 pb-2">我們最後如何解決：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post33_DataB || '--') }}></div>

                                        <div className="fw-bolder text-second pt-3 pb-2">我們學到的新思維：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post33_DataC || '--') }}></div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{formatDateTime(opinion?.Post33_UpdateDate)}</span>
                                        </div>
                                    </div>
                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">階段3，指導老師留言</h5>
                                    <div className="p-3">
                                        <div className="fw-bolder text-second pb-2">老師留言：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post39_DataA || '') }}></div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{formatDateTime(opinion?.Post39_UpdateDate)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (<></>)}
                    {/*  level 4 */}

                    {isDisplay('level4') ? (
                        <div className="accordion-item">
                            <div className="card bd-callout bd-callout-danger">
                                <button
                                    className="collapsed accordion-button text-dark p-0"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#level4"
                                    aria-expanded="false"
                                    aria-controls="level4"
                                >
                                    <h4 className="my-0 me-3">階段4、整合組織資料</h4>
                                    <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                    <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                </button>
                                <div className="d-flex flex-wrap z-index-3 position-absolute" style={{ left: '280px' }}>
                                    <div className="btn text-second bg-second mb-0 me-3 pointer" data-bs-toggle="modal" data-bs-target="#teach4">
                                        怎樣歸納統整資料
                                    </div>
                                    <div className="btn text-second bg-second mb-0 me-3 pointer" data-bs-toggle="modal" data-bs-target="#teach4-2">
                                        怎樣提出結論或發現
                                    </div>
                                    <div className="btn text-second bg-second mb-0 pointer" onClick={() => Export2Word('print-section-4', '階段4、整合組織資料', getFileNameString(selectedGroup, undefined))}>
                                        匯出檔案
                                    </div>
                                </div>
                            </div>

                            <div className="collapse" id="level4" data-bs-parent="#accordionRental">
                                <div className="card p-4 pt-3" id="print-section-4">
                                    <h5 className="fw-bolder pt-3">4-1 提出各子題小結</h5>
                                    <div className="btn text-second bg-second mx-auto mb-0">各子題小結的檔案下載</div>
                                    <div className="modal-body text-center my-3 px-0" style={{ height: '70vh', maxHeight: '530px', overflowY: 'auto' }}>
                                        <object
                                            data="https://eliteracy.twnread.org.tw/Uploads/111/pdf4_1/Step4-EA011-01新路國小.pdf"
                                            type="application/pdf"
                                            width="95%"
                                            style={{ height: '64vh', overflowX: 'auto' }}
                                        ></object>
                                    </div>

                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">4-2 提出探究題目的結論</h5>
                                    <div className="p-3">
                                        <div className="fw-bolder text-second pt-3 pb-2">提出結論或發現：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post42_DataB || '--') }}></div>

                                        <div className="fw-bolder text-second pt-3 pb-2">我們的結論可能的限制：</div>

                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post42_DataC || '') }}></div>

                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{formatDateTime(opinion?.Post42_UpdateDate)}</span>
                                        </div>
                                    </div>
                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">4-3 比較探究結論與先前的預測</h5>
                                    <div className="p-3">
                                        <div className="fw-bolder text-second">探究前的預測：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post13_DataB || '--') }}></div>
                                    </div>
                                    <div className="p-3">
                                        <div className="fw-bolder text-second pt-3 pb-2">與最後的結論相比較：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post43_DataB || '--') }}></div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{formatDateTime(opinion?.Post43_UpdateDate)}</span>
                                        </div>
                                    </div>
                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">4-4 困難、解決與新學習</h5>
                                    <div className="p-3">
                                        <div className="fw-bolder text-second pt-3 pb-2">我們在「階段4」遇到的困難：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post44_DataA || '') }}></div>

                                        <div className="fw-bolder text-second pt-3 pb-2">我們最後如何解決：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post44_DataB || '') }}></div>

                                        <div className="fw-bolder text-second pt-3 pb-2">我們學到的新思維：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post44_DataC || '') }}></div>
                                        {/* <div>1.做任何事都要有行動力，尤其是遇到大家意見不一樣的時候，必須有"實驗行動 "能力，找出最佳答案。</div>
                                        <div>2.做事前須有萬全的準備，而且以後除了要細看文字說明外，還必須要從頭到尾閱讀，這樣計畫才能周延，做事也才能事半功倍。</div> */}

                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{formatDateTime(opinion?.Post44_UpdateDate)}</span>
                                        </div>
                                    </div>

                                    <hr className="horizontal dark" />
                                    <h5 className="fw-bolder pt-3">階段4，指導老師留言</h5>
                                    <div className="p-3">
                                        <div className="fw-bolder text-second pt-3 pb-2">老師留言：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post49_DataA || '') }}></div>
                                        <div className="pt-3 pb-2">
                                            <span className="fw-bolder text-second">日期：</span>
                                            <span>{formatDateTime(opinion?.Post49_UpdateDate)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    ) : (<></>)}
                    {/*  level 5 */}
                    {isDisplay('level5') ? (
                        <div className="accordion-item">
                            <div className="card bd-callout bd-callout-danger">
                                <button
                                    className="collapsed accordion-button text-dark p-0"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#level5"
                                    aria-expanded="false"
                                    aria-controls="level5"
                                >
                                    <h4 className="my-0 me-2">成果報告</h4>
                                    <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                    <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="collapse" id="level5" data-bs-parent="#accordionRental">
                                <div className="card p-4 pt-3">
                                    <h5 className="fw-bolder pt-3">探究題目是否微調？</h5>
                                    <div className="pt-3">
                                        <span className="fw-bolder text-second">原探究題目：</span>
                                        <span className="text-primary" dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Pre12_DataA || '--') }}></span>
                                    </div>
                                    <div className="fw-bolder text-second pt-3">修改後的探究題目為：</div>
                                    <div className="d-flex flex-wrap align-items-center pb-3" dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post12_DataA || '') }}></div>

                                    <hr className="horizontal dark" />
                                    <div id="print-section-5">
                                        <h5 className="fw-bolder pt-3">學生省思</h5>
                                        <label className="fw-bolder text-second">省思</label>
                                        <div className="pb-3" dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post54_DataA || '--') }}></div>
                                        <hr className="horizontal dark" />

                                        <h5 className="fw-bolder pt-3">
                                            指導老師心得<span className="fs-16">(111年度起新增必填)</span>
                                        </h5>
                                        <div className="p-3">
                                            <div className="fw-bolder text-second pt-3 pb-2">老師心得：</div>
                                            <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post55_DataA || '--') }}></div>
                                            <div className="pt-3 pb-2">
                                                <span className="fw-bolder text-second">日期：{formatDateTime(opinion?.Post55_UpdateDate)}</span>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="horizontal dark" />

                                    <h5 className="fw-bolder pt-3">成果報告</h5>
                                    <div className="mb-3">
                                        <div className="btn text-second bg-second mx-2" onClick={() => Export2Word('print-section-1-post', '階段1', getFileNameString(selectedGroup, undefined))}>
                                            階段1
                                        </div>
                                        <div className="btn text-second bg-second mx-2" onClick={() => Export2Word('print-section-2-post', '階段2', getFileNameString(selectedGroup, undefined))}>
                                            階段2
                                        </div>
                                        <div className="btn text-second bg-second mx-2" onClick={() => Export2Word('print-section-3', '階段3', getFileNameString(selectedGroup, undefined))}>
                                            階段3
                                        </div>
                                        <div className="btn text-second bg-second mx-2" onClick={() => Export2Word('print-section-4', '階段4', getFileNameString(selectedGroup, undefined))}>
                                            階段4
                                        </div>
                                        <div className="btn text-second bg-second mx-2" onClick={() => Export2Word('print-section-5', '階段5', getFileNameString(selectedGroup, undefined))}>
                                            階段5(省思與心得)
                                        </div>
                                        <div className="btn text-second bg-second mx-2" onClick={() => Export2Word('print-section-all', '階段1~5', getFileNameString(selectedGroup, undefined))}>
                                            階段1~5
                                        </div>
                                        <div className="btn text-second bg-second mx-2" onClick={() => Export2Word('refer_source', '參考資料', getFileNameString(selectedGroup, undefined))}>
                                            參考資料
                                        </div>
                                    </div>

                                    <hr className="horizontal dark" />

                                    <h5 className="fw-bolder pt-3">決賽簡報</h5>
                                    <div className="fw-bolder text-second pt-3 pb-2">
                                        檔案上傳日期：<span className="text-dark fw-normal">{formatDateTime(uploadtime)}</span>
                                    </div>

                                    <div className="mb-3">
                                        <div className="btn text-second bg-second mx-2">成果報告檔案</div>
                                        <div className="btn text-second bg-second mx-2">成果Powerpoint檔</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-4 text-center">
                                            <div className="rounded-3 p-3 imgborder mw-100 w-100 me-3 print-remove">
                                                <img src="./assets/img/ep/file_upload.png" alt="" className="mw-100" />
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 text-center">
                                            <div className="rounded-3 p-3 imgborder mw-100 w-100 me-3 print-remove">
                                                <img src="./assets/img/ep/file_upload.png" alt="" className="mw-100" />
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 text-center">
                                            <div className="rounded-3 p-3 imgborder mw-100 w-100 me-3 print-remove">
                                                <img src="./assets/img/ep/file_upload.png" alt="" className="mw-100" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (<></>)}
                </div>

            </div>
            {genRefSourcePrint()}

            <div className="pt-3 mt-4"></div>


            {/* <!-- 階段1 教學 --> */}
            <div className="modal fade" id="teach1" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '720px' }}>
                    <div className="modal-content mx-3">
                        <div className="modal-header">
                            <h5 className="modal-title">階段1 教學</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" className="text-dark" style={{ fontSize: '36px', lineHeight: '12px' }}>
                                    &times;
                                </span>
                            </button>
                        </div>
                        <div className="modal-body text-center pb-3 px-0" style={{ overflowY: 'auto' }}>
                            <iframe
                                src="//www.slideshare.net/slideshow/embed_code/key/HL4VzukgbKTTV9"
                                className="w-100 border-radius-lg"
                                style={{ border: '1px solid #CCC', height: '400px;max-width: 620px' }}
                                allowFullScreen
                            ></iframe>
                            <div>
                                <a href="//www.slideshare.net/LoveBook2/1202301pptx" title="數位閱讀專題探究競賽 - 怎樣選擇題目" target="_blank">
                                    數位閱讀專題探究競賽 - 怎樣選擇題目
                                </a>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                關閉
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- 階段2 教學 --> */}
            <div className="modal fade" id="teach2" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '720px' }}>
                    <div className="modal-content mx-3">
                        <div className="modal-header">
                            <h5 className="modal-title">階段2 教學</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" className="text-dark" style={{ fontSize: '36px', lineHeight: '12px' }}>
                                    &times;
                                </span>
                            </button>
                        </div>
                        <div className="modal-body text-center pb-3 px-0" style={{ overflowY: 'auto' }}>
                            <iframe
                                src="//www.slideshare.net/slideshow/embed_code/key/toNtzJl5dVvQVd"
                                className="w-100 border-radius-lg"
                                style={{ border: '1px solid #CCC', height: '400px', maxWidth: '620px' }}
                                allowFullScreen
                            ></iframe>
                            <div>
                                <a href="//www.slideshare.net/LoveBook2/2202301pptx" title="數位閱讀專題探究競賽 - 怎樣規劃探究架構" target="_blank">
                                    數位閱讀專題探究競賽 - 怎樣規劃探究架構
                                </a>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                關閉
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- 階段3 教學 --> */}
            <div className="modal fade" id="teach3" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '720px' }}>
                    <div className="modal-content mx-3">
                        <div className="modal-header">
                            <h5 className="modal-title">階段3 教學 - 怎樣選擇題目</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" className="text-dark" style={{ fontSize: '36px;', lineHeight: '12px' }}>
                                    &times;
                                </span>
                            </button>
                        </div>
                        <div className="modal-body text-center pb-3 px-0" style={{ overflowY: 'auto' }}>
                            <iframe
                                src="//www.slideshare.net/slideshow/embed_code/key/g8zXWc7qtfNNjn"
                                className="w-100 border-radius-lg"
                                style={{ border: '1px solid #CCC', height: '400px', maxWidth: '620px' }}
                                allowFullScreen
                            ></iframe>
                            <div>
                                <a href="//www.slideshare.net/LoveBook2/3202301pptx" title="數位閱讀專題探究競賽 - 怎樣蒐集資料" target="_blank">
                                    數位閱讀專題探究競賽 - 怎樣選擇題目
                                </a>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                關閉
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- 階段3.2 教學 --> */}
            <div className="modal fade" id="teach3-2" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '720px' }}>
                    <div className="modal-content mx-3">
                        <div className="modal-header">
                            <h5 className="modal-title">階段3 教學 - 怎樣判斷資料的可信度</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" className="text-dark" style={{ fontSize: '36px', lineHeight: '12px' }}>
                                    &times;
                                </span>
                            </button>
                        </div>
                        <div className="modal-body text-center pb-3 px-0" style={{ overflowY: 'auto' }}>
                            <iframe
                                src="//www.slideshare.net/slideshow/embed_code/key/r5peeTpp761fAh"
                                className="w-100 border-radius-lg"
                                style={{ border: '1px solid #CCC', height: '400px', maxWidth: '620px' }}
                                allowFullScreen
                            ></iframe>
                            <div>
                                <a href="//www.slideshare.net/LoveBook2/4202301pptx" title="數位閱讀專題探究競賽 - 怎樣蒐集資料" target="_blank">
                                    數位閱讀專題探究競賽 - 怎樣判斷資料的可信度
                                </a>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                關閉
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- 階段4 教學 --> */}
            <div className="modal fade" id="teach4" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '720px' }}>
                    <div className="modal-content mx-3">
                        <div className="modal-header">
                            <h5 className="modal-title">階段4 教學 - 怎樣歸納統整資料</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" className="text-dark" style={{ fontSize: '36px', lineHeight: '12px' }}>
                                    &times;
                                </span>
                            </button>
                        </div>
                        <div className="modal-body text-center pb-3 px-0" style={{ overflowY: 'auto' }}>
                            <iframe
                                src="//www.slideshare.net/slideshow/embed_code/key/sF3WTJjXayexwq"
                                className="w-100 border-radius-lg"
                                style={{ border: '1px solid #CCC', height: '400px', maxWidth: '620px' }}
                                allowFullScreen
                            ></iframe>
                            <div>
                                <a href="//www.slideshare.net/LoveBook2/5202301pptx" title="數位閱讀專題探究競賽 - 怎樣蒐集資料" target="_blank">
                                    數位閱讀專題探究競賽 - 怎樣歸納統整資料
                                </a>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                關閉
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- 階段4.2 教學 --> */}
            <div className="modal fade" id="teach4-2" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '720px' }}>
                    <div className="modal-content mx-3">
                        <div className="modal-header">
                            <h5 className="modal-title">階段4 教學 - 怎樣提出結論或發現</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" className="text-dark" style={{ fontSize: '36px', lineHeight: '12px' }}>
                                    &times;
                                </span>
                            </button>
                        </div>
                        <div className="modal-body text-center pb-3 px-0" style={{ overflowY: 'auto' }}>
                            <iframe
                                src="//www.slideshare.net/slideshow/embed_code/key/8DYUbEJVfM11n2"
                                className="w-100 border-radius-lg"
                                style={{ border: '1px solid #CCC', height: '400px', maxWidth: '620px' }}
                                allowFullScreen
                            ></iframe>
                            <div>
                                <a href="//www.slideshare.net/LoveBook2/6202301pptx" title="數位閱讀專題探究競賽 - 怎樣蒐集資料" target="_blank">
                                    數位閱讀專題探究競賽 - 怎樣提出結論或發現
                                </a>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                關閉
                            </button>
                        </div>
                    </div>
                </div>
            </div>



        </>
    );
};
export default AllOpinion;
