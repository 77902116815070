import React, { useEffect, useState } from 'react';
import IPageProps from '../../../interfaces/page';
import { handleLink } from '../../../modules/utils';
import { getDrControlByClassNow } from '../../../services/EP/EPbtnEableService';
import { currentClass, currentClassForBtn, logOut } from '../tool/helper';
import { crtlInfo } from '../../../interfaces/EpModal';
interface IPropBar{

    crtlInfoList :crtlInfo[] |undefined
    // currentPage : string  

}

/**epApplyHome.html  【6-4-5	小隊基本資料】*/
const ApplyFunctionBar: React.FunctionComponent<IPropBar> = ({crtlInfoList}) => {
    // const [crtlInfoList, setCtrlInfoList] = useState<crtlInfo[]>([]);
    const getCtrl = (control: string) => {
        return true 
        let ctrl = (crtlInfoList||[]).find((x) => x.Control == control);

        if (ctrl) {
            return true;
        } else {
            return false;
        }
    };

    const link = (ctrl: string ,route:string) => {
        if (getCtrl(ctrl)) {
            handleLink(route);
        } else {

        }
    };

    const disableStyle ={
        color:'gray',
        background :'#dddddd'

    }
    return (
        <>
            {/* {JSON.stringify(crtlInfoList)} */}
            <div className="text-center text-lg-end">
                <a
                    href={getCtrl('6-4-3') ? '/epApplyHome' : undefined}
                    onClick={() => {
                        link('6-4-3' ,'/epApplyHome');
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3 "
                    style={getCtrl('6-4-3') ?{}:disableStyle }
                    type="button"
                >
                    承辦人主頁
                </a>

                <a
                    href={getCtrl('6-4-4') ? '/epApplyTeacher' : undefined}
                    onClick={() => {
                        link('6-4-4','/epApplyTeacher');
                    }}
                    style={getCtrl('6-4-4') ?{}:disableStyle }
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    type="button"
                >
                    指導老師資料 {getCtrl('6-4-4')} 
                </a>
                <a
                    href={getCtrl('6-4-5') ? '/epApplyGroup' : undefined}
                    onClick={() => {
                        link('6-4-5','/epApplyGroup');
                    }}
                    style={getCtrl('6-4-5') ?{}:disableStyle }
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    type="button"
                >
                    小隊基本資料
                </a>
                <a
                    href="/epApplyExplore"
                    onClick={() => {
                        link('6-4-6','/epApplyExplore');
                    }}
                    style={getCtrl('6-4-6') ?{}:disableStyle }
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    type="button"
                >
                    專題探究
                </a>
                <a
                    href="epApplyLogin.html"
                    onClick={() => {
                        link('6-4-7','/epNewPage');
                    }}
                    style={getCtrl('6-4-7') ?{}:disableStyle }
                    className="btn btn-outline-secondary btn-round text-nowrap　me-2 me-sm-3    "
                    type="button"
                >
                    登入記錄
                </a>

                <a
                    href="epApplyLogin.html"
                    onClick={() => {
                        logOut();
                    }}
                    style={getCtrl('6-4-7') ?{}:disableStyle }
                    className="btn btn-outline-secondary btn-round text-nowrap"
                    type="button"
                >
                    登出
                </a>
            </div>
        </>
    );
};
export default ApplyFunctionBar;
