import React, { useEffect, useState } from 'react';
import IPageProps from '../../../interfaces/page';
import { handleLink } from '../../../modules/utils';
import { crtlInfo } from '../../../interfaces/EpModal';
import { logOut } from '../tool/helper';



interface IProp {
    currentPage? :string 
 
}

/**epApplyHome.html  【6-4-5	小隊基本資料】*/
const ExpertFunctionBar: React.FunctionComponent<IProp> = (props) => {
    const [crtlInfoList, setCtrlInfoList] = useState<crtlInfo[]>([]);


    const getCtrl = (control: string) => {
        let ctrl = crtlInfoList.find((x) => x.Control === control);
return true 
        if (ctrl) {
            return true;
        } else {
            return false;
        }
    };

    const link = (ctrl: string, route: string) => {
        if (getCtrl(ctrl)) {
            handleLink(route);
        } else {
        }
    };

    const disableStyle = {
        color: 'gray',
        background: '#dddddd'
    };

    return (
        <>
            <div className="text-center text-lg-end">
                <a
                  href={getCtrl('6-4-20') ? '/epExpertHome' : undefined}
                    onClick={() => {
                        handleLink('/epExpertHome');
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3 "
                    type="button"
                    style={getCtrl('6-4-20') ? {} :disableStyle }
                >
                    專家學者首頁  {props.currentPage}
                </a>
                <a
                    href={getCtrl('6-4-21') ? '/epExpertGroup' : undefined}
                    onClick={() => {
                        handleLink('/epExpertGroup');
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    style={getCtrl('6-4-21') ? {} :disableStyle }
                    type="button"
                >
                    小隊基本資料
                </a>
                <a
                    href={getCtrl('6-4-22') ? '/epExpertFeedback' : undefined}
                    onClick={() => {
                        handleLink('/epExpertFeedback');
                    }}
                    
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    style={getCtrl('6-4-22') ? {} :disableStyle }
                    type="button"
                >
                    探究(回饋前)
                </a>
                <a
                    href={getCtrl('6-4-23') ? '/epExpertOpinion' : undefined}
                    onClick={() => {
                        handleLink('/epExpertOpinion');
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    style={getCtrl('6-4-23') ? {} :disableStyle }
                    type="button"
                >
                    回饋意見
                </a>
                <a
                    href={getCtrl('6-4-24') ? '/epExpertComment' : undefined}
                    onClick={() => {
                        handleLink('/epExpertComment');
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    style={getCtrl('6-4-24') ? {} :disableStyle }
                    type="button"
                >
                    參考評語
                </a>

                <a
                    href="epApplyLogin.html"
                    onClick={() => {
                        logOut();
                    }}
                    style={getCtrl('6-4-7') ?{}:disableStyle }
                    className="btn btn-outline-secondary btn-round text-nowrap"
                    type="button"
                >
                    登出
                </a>
            </div>
        </>
    );
};
export default ExpertFunctionBar;
