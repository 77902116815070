import React, { useEffect, useState } from 'react';
import IPageProps from '../../../interfaces/page';
import { handleLink } from '../../../modules/utils';
import { crtlInfo } from '../../../interfaces/EpModal';
import { getcontrol, logOut } from '../tool/helper';

/**epApplyHome.html  【6-4-5	小隊基本資料】*/
const TeacherFunctionBar: React.FunctionComponent<IPageProps> = (props) => {
    const [crtlInfoList, setCtrlInfoList] = useState<crtlInfo[]>([]);
 

    const getCtrl = (control: string) => {
        return true 
  
    };
    const link = (ctrl: string ,route:string) => {
      if (getCtrl(ctrl)) {
          handleLink(route);
      } else {

      }
  };


  const disableStyle = {
    color: 'gray',
    background: '#dddddd'
};



    return (
        <>
            <div className="text-center text-lg-end">
                <a
                    href={getCtrl('6-4-14') ||true? '/epTeachHome' : undefined}
                    onClick={() => {
                        link('6-4-14' ,'/epTeachHome');
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3 "
                    style={getCtrl('6-4-14') ||true? {} :disableStyle }
                    type="button"
                > 
                    指導老師主頁 
                </a>
                <a
                    href={getCtrl('6-4-15') ||true? '/epTeachLevel1' : undefined}
                    onClick={() => {
                        link('6-4-15' ,'/epTeachLevel1');
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    style={getCtrl('6-4-15') ||true ? {} :disableStyle }
                    type="button"
                >
                    階段1和2
                </a>
                <a
                    href={getCtrl('6-4-15') ||true? '/EpTeachExpert' : undefined}
                    onClick={() => {
                        link('6-4-15' ,'/EpTeachExpert');
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    style={getCtrl('6-4-15') ||true ? {} :disableStyle }
                    type="button"
                >
                    專家回饋
                </a>
                <a
                    href={getCtrl('6-4-15') ||true? '/epTeachfeedback' : undefined}
                    onClick={() => {
                        link('6-4-15' ,'/epTeachfeedback');
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    style={getCtrl('6-4-15') ||true ? {} :disableStyle }
                    type="button"
                >
                      階段1和2(回饋後)
                </a>

         
                <a
                    href={getCtrl('6-4-15') ||true? '/epTeachLevel3' : undefined}
                    onClick={() => {
                        link('6-4-15' ,'/epTeachLevel3');
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    style={getCtrl('6-4-15') ||true ? {} :disableStyle }
                    type="button"
                >
                       階段3和4
                       </a>
           

                       <a
                    href={getCtrl('6-4-15') ||true? '/epTeachResult' : undefined}
                    onClick={() => {
                        link('6-4-15' ,'/epTeachResult');
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    style={getCtrl('6-4-15') ||true ? {} :disableStyle }
                    type="button"
                >
                         成果報告
                       </a>
                       <a
                    href="epApplyLogin.html"
                    onClick={() => {
                        logOut();
                    }}
                    style={getCtrl('6-4-7') ?{}:disableStyle }
                    className="btn btn-outline-secondary btn-round text-nowrap"
                    type="button"
                >
                    登出
                </a>
           
            </div>
        </>
    );
};
export default TeacherFunctionBar;
